<template>
  <!-- Modal -->
  <div class="modal" id="legajoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ legajosButtonText }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--
            {{ item }}
          -->
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Caja (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="item.name" disabled="true">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Inventario (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.lgj_nmro" v-bind:disabled="!add">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Nombre: </label>
                  <input type="text" class="form-control col-md-8" v-model="item.lgj_nmbre">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Unidad de conservación (*):</label>
                  <select v-model="item.uco_id" class="form-control col-md-8" required>
                    <option v-for="i in uconservs" v-bind:value="i._id">
                      {{ i.uco_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-item', item)">{{ legajosButtonText }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['legajosButtonText', 'add', 'item'],
  data() {
    return {
      //item: {}
      uconservs: [],
      focused: false
    }
  },
  created: function(){
    this.fetchUconserv();
  },
  mounted: function() {

  },
  methods: {
    fetchUconserv()
    {
      let uri = '/uconservs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.uco_nmbre > b.uco_nmbre) { return 1; };
          if (a.uco_nmbre < b.uco_nmbre) { return -1; };
          return 0;
        });
        this.uconservs = list;
      });
    }


  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
