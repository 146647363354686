<template>
  <!-- Modal -->
  <div class="modal" id="prestarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Prestar documentos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Solicitante (*):</label>
                  <input type="text" v-model="item.prm_solicitante" class="form-control col-md-8" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Descripción (*):</label>
                  <input type="text" v-model="item.prm_descripcion" class="form-control col-md-8" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Fecha de vencimiento (*):</label>
                  <input type="date" class="form-control col-md-8" v-model="item.prm_fecha_vencimiento" required>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-item', item)">Prestar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['item'],
  data() {
    return {
      //item: {}
      focused: false
    }
  },
  created: function(){

  },
  mounted: function() {

  },
  methods: {


  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 100%;
}
*/
</style>
