import { render, staticRenderFns } from "./DisplayDoc.vue?vue&type=template&id=51bd27a4&"
import script from "./DisplayDoc.vue?vue&type=script&lang=js&"
export * from "./DisplayDoc.vue?vue&type=script&lang=js&"
import style0 from "./DisplayDoc.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu,VProgressCircular,VTreeview})
