<template>
  <!-- Modal -->
  <div class="modal" id="archivarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Archivar documentos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--
            {{ item }}
          -->
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Tipo de archivo (*):</label>
                  <v-autocomplete
                    v-model="item.tpa_id"
                    :items="tpoarchs"
                    required
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Sala (*):</label>
                  <v-autocomplete
                    v-model="item.sal_id"
                    :items="salas"
                    @change="setSala"
                    required
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Estante (*):</label>
                  <v-autocomplete
                    v-model="item.est_id"
                    :items="estantes"
                    @change="setEstante"
                    required
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Caja (*): </label>
                  <v-autocomplete
                    v-model="item.caj_cdgo"
                    :items="cajas"
                    @change="setCaja"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Legajo (*): </label>
                  <v-autocomplete
                    v-model="item.lgj_nmro"
                    :items="legajos"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-item', item)">Archivar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['item'],
  data() {
    return {
      //item: {}
      tpoarchs: [],
      salas: [],
      estantes: [],
      cajas: [],
      legajos: [],
      focused: false
    }
  },
  created: function(){
    this.fetchTpoarch();
    this.fetchUsuxsal();
    //this.fetchEstante();
    //this.fetchCaja();
    //this.fetchLegajo();
  },
  mounted: function() {

  },
  methods: {
    fetchTpoarch()
    {
      let uri = '/tpoarchs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpoarchs = response.data.map(i => {
          return {
            text: i.tpa_nmbre,
            value: i._id
          }
        });
      });
    },
    fetchUsuxsal()
    {
      let uri = '/uxs/salas';
      this.axios.post(uri, { cmp_id: this.$store.state.company, usu_id: this.$store.state.user, uxs_mdfcr: true }).then((response) => {
        this.salas = response.data.map(i => {
          return {
            text: i.sal_nmbre,
            value: i._id
          }
        });

        this.salas.sort(function(a,b){
          if (a.text > b.text) { return 1; };
          if (a.text < b.text) { return -1; };
          return 0;
        });
      });
    },
    setSala(e){
      var sala = e;

      let uri = '/estantes/sala';
      this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: sala }).then((response) => {
        this.estantes = response.data.map(i => {
          return {
            text: i.est_cdgo,
            value: i._id
          }
        });
      })
      .catch((err) => {
        alert('No existen estantes relacionados con esta sala.')
      });
    },
    setEstante(e){
      var estante = e;

      let uri = '/cajas/estante/tpoarch';
      this.axios.post(uri, { cmp_id: this.$store.state.company, est_id: estante, tpa_id: this.item.tpa_id }).then((response) => {
        this.cajas = response.data.map(i => {
          let text = i.caj_cdgo;
          if (i.caj_nmbre) {
            text += " - " + i.caj_nmbre;
          }

          return {
            _id: i._id,
            text,
            value: i.caj_cdgo
          }
        });

      })
      .catch((err) => {
        alert('No existen cajas relacionadas con este estante.')
      });
    },
    setCaja(e){
      var caja = e;
      var caj_id = '';
      for (let i = 0; i < this.cajas.length; i++){
        if (caja == this.cajas[i].value){
          caj_id = this.cajas[i]._id;
        }
      }

      let uri = '/legajos/caja';
      this.axios.post(uri, { cmp_id: this.$store.state.company, caj_id: caj_id }).then((response) => {
        this.legajos = response.data.map(i => {
          let text = i.lgj_nmro;
          if (i.lgj_nmbre) {
            text += " - " + i.lgj_nmbre;
          }

          return {
            text,
            value: i.lgj_nmro
          }
        });

      })
      .catch((err) => {
        alert('No existen legajos relacionados con esta caja.')
      });
    }



  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 100%;
}
*/
</style>
