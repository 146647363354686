<template>
  <!-- Modal -->
  <div class="modal" id="cajaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ cajasButtonText }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--
            {{ item }}
          -->
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Sala (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="item.sal_nmbre" disabled="true">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Tipo de archivo (*):</label>
                  <select v-model="item.tpa_id" class="form-control col-md-8" required v-bind:disabled="!add">
                    <option v-for="i in tpoarchs" v-bind:value="i._id">
                      {{ i.tpa_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Caja (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.caj_cdgo" v-bind:disabled="!add">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Nombre: </label>
                  <input type="text" class="form-control col-md-8" v-model="item.caj_nmbre">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Estante (*):</label>
                  <!--
                  <input type="text" class="form-control col-md-8" v-model="item.est_nmbre" disabled>
                  -->
                  <select v-model="item.est_id" class="form-control col-md-8" required>
                    <option v-for="i in estantes" v-bind:value="i._id">
                      {{ 'Estante ' + i.est_cdgo }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Bandeja (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.caj_bndja">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-item', item)">{{ cajasButtonText }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['cajasButtonText', 'estantes', 'add', 'item'],
  data() {
    return {
      //item: {}
      //estantes: [],
      tpoarchs: [],
      focused: false
    }
  },
  created: function(){
    //this.fetchEstante();
    this.fetchTpoarch();
  },
  mounted: function() {

  },
  methods: {
    fetchEstante(){
      let uri = '/estantes/edit/' + this.item.est_id;
      this.axios.get(uri).then((response) => {
        this.estantes = response.data;
      });
    },
    fetchTpoarch()
    {
      let uri = '/tpoarchs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.tpa_nmbre > b.tpa_nmbre) { return 1; };
          if (a.tpa_nmbre < b.tpa_nmbre) { return -1; };
          return 0;
        });
        this.tpoarchs = list;
      });
    }


  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
