<template>
      <!-- Modal -->
      <div class="modal" id="timelineModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Seguimiento</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <file-icon class="custom-class"></file-icon><span>{{ detalles.doc }}</span>
                <ul class="timeline">
                  <li v-for="(item, index) in items">
                    <span class="text-success">{{ item.deUsuario }}</span>
                    <span class="text-secondary"> --> </span>
                    <span class="text-primary">{{ item.title }}</span>
                    <span class="float-right text-primary">{{ item.from | formatDate }}</span>
                    <p>{{ item.description }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <!-- End Modal -->
</template>
 
<script>
import { FileIcon } from 'vue-feather-icons';
import { formatDate } from './../../plugins/filters';

export default {
  components: {
    FileIcon
  },
  props: ['detalles', 'items'],
  data() {
    return {

    }
  },
  filters: {
    formatDate
  },
  mounted(){

  }
}
</script> 
<style>
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}  
</style>