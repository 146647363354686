<template>
  <!-- Modal -->
  <div class="modal" id="estanteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ estantesButtonText }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Sala (*):</label>
                  <select v-model="item.sal_id" class="form-control col-md-8" required disabled="true">
                    <option v-for="i in salas" v-bind:value="i._id">
                      {{ i.sal_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Estante (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.est_cdgo">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Bandejas (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.est_bndjas">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Cajas por bandeja (*): </label>
                  <input type="number" class="form-control col-md-8" v-model="item.est_cjasxb">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-item', item)">{{ estantesButtonText }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['estantesButtonText', 'item'],
  data() {
    return {
      //item: {}
      salas: [],
      focused: false
    }
  },
  created: function(){
    this.fetchSala();
  },
  mounted: function() {

  },
  methods: {
    fetchSala()
    {
      let uri = '/salas/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.salas = response.data;
      });
    }

  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>