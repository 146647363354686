<template>
  <div id="documentos">
    <ModalForm :visible="showProgress" @close="showProgress=false" :message="modalMsg" />
    <div id="header">
      <div id="funciones">
        <div class="text-center">
          <h1>Documentos</h1>
        </div>
        <!--
        <div v-if="filter">
          <button v-on:click="showQueries('C')" class="btn btn-link"><arrow-left-icon class="custom-class"></arrow-left-icon>Cerrar búsqueda</button>
        </div>
        -->
        <div class="form-row">
          <div class="form-group col-md-6">
            <form v-on:submit.prevent="showQueries('B')">
              <div class="form-row">
                <div class="form-group">
                  <input type="text" v-model="keywords" v-bind:disabled="searchingItems" class="form-control" placeholder="Buscar">
                </div>
                <div class="form-group">
                  <i class="fas fa-question-circle" title="Escriba la(s) palabra(s) clave(s). Para buscar uno o varios radicados específicos utilice el formato RAD:[TIPO]-[AÑO]-[CONSECUTIVO]+..., por ejemplo: RAD:R-2019-1234+R-2020-456+E-2020-237"></i>
                  <button type="submit" v-bind:disabled="searchingItems" class="btn btn-primary"><search-icon class="custom-class"></search-icon></button>
                  <button type="button" v-on:click="newSearch()" v-bind:disabled="searchingItems" class="btn btn-primary" data-toggle="modal" data-target="#searchModal">
                    Avanzada
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="form-group col-md-6" v-if="can_add && (!showArchivo || showArchivo && allowModify)">
            <button v-on:click.prevent="addItem()" v-bind:disabled="searchingItems||!showDcmnts" class="btn btn-primary"><plus-circle-icon class="custom-class"></plus-circle-icon>Crear documento</button>
          </div>
        </div>
        <!--
        {{ open }}
        <br>
        {{ active }}
        -->
        <!--
        <div class="list-group" id="list-tab" role="tablist" v-if="!filter">
          <a v-for="(i, index) in tpocors" class="list-group-item list-group-item-action" id="tabName(index)" data-toggle="list" href="#list-doc" role="tab" aria-controls="doc" v-on:click="searchItems({ tcr_id: i._id, today: true })">{{ i.tcr_nmbre }}</a>
          <a class="list-group-item list-group-item-action" id="list-ARCHIVO" data-toggle="list" href="#list-doc-archivo" role="tab" aria-controls="archivo"><archive-icon class="custom-class"></archive-icon>Archivo</a>
        </div>
        -->
        <!--<ul class="nav nav-pills" id="myTab" role="tablist" v-if="!filter">-->
        <div class="row">
          <ul class="nav nav-pills" id="myTab" role="tablist">
            <li class="nav-item"><a v-on:click="showQueries('C')" class="nav-link" id="list-ACTUAL" data-toggle="tab" href="#list-doc-actual" role="tab" aria-controls="actual" aria-selected="false"><refresh-cw-icon class="custom-class"></refresh-cw-icon></a></li>
            <li v-for="(i, index) in tpocors" class="nav-item"><a class="nav-link" v-bind:ref="i.tcr_cdgo" v-bind:id="i.tcr_cdgo" data-toggle="tab" href="#list-doc" role="tab" aria-controls="doc" aria-selected="false" v-on:click="showQueries('T', i._id)">{{ i.tcr_nmbre }}</a></li>
            <li class="nav-item"><a v-on:click="showQueries('P')" class="nav-link" id="list-PENDIENTE" data-toggle="tab" href="#list-doc-pendiente" role="tab" aria-controls="pendiente" aria-selected="false"><p style="color:#c26209"><alert-circle-icon class="custom-class"></alert-circle-icon>Pendiente</p></a></li>
            <li class="nav-item"><a v-on:click="showQueries('S')" class="nav-link" id="list-SINLEER" data-toggle="tab" href="#list-doc-sinleer" role="tab" aria-controls="sinleer" aria-selected="false"><p style="color:#c26209"><target-icon class="custom-class"></target-icon>Sin leer</p></a></li>
            <li class="nav-item"><a v-on:click="clickArchivo" class="nav-link" id="list-ARCHIVO" data-toggle="tab" href="#list-doc-archivo" role="tab" aria-controls="archivo" aria-selected="false">
              <archive-icon class="custom-class"></archive-icon>
              Archivo</a></li>
          </ul>        
        </div>
      </div>
    </div>
    <div id="area">
      <div id="container">
        <div id="right_panel" v-bind:class="{ 'col bgitems': showArchivo, 'col listitems': !showArchivo }">
          <!-- TABLA DCMNTS -->
          <div class="" v-if="showDcmnts">                
            <div v-if="filter && showDcmnts">
              <p v-if="showArchivo" class="path-text">{{ path }}</p>

              <div class="d-flex">
                <div v-if="showArchivo && can_add && allowModify" class="mr-5">
                  <button v-on:click="addItem()" v-bind:disabled="searchingItems||!showDcmnts" class="btn btn-primary"><plus-circle-icon class="custom-class"></plus-circle-icon>Crear documento</button>
                </div>

                <!--
                <div v-if="showArchivo && can_add" class="">
                 <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      class="btn btn-primary text-white"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Hoja de control
                      <v-icon class="text-white">mdi-chevron-down</v-icon>
                    </button>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, i) in menu_control_sheet_v2"
                      :key="i"
                      @click="generateControlSheet_v2(item)"
                    >
                      <v-list-item-title>
                        <v-icon large :color="item.color" > {{item.icon}} </v-icon>
                        {{ item.text }}
                        </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  </v-menu>
                </div>
                -->

                <!--
                <div v-if="showArchivo && can_add" class="">
                 <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      class="btn btn-primary text-white"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Generar hoja de control
                      <v-icon class="text-white">mdi-chevron-down</v-icon>
                    </button>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, i) in menu_control_sheet"
                      :key="i"
                      @click="generateControlSheet(item)"
                    >
                      <v-list-item-title>
                        <v-icon large :color="item.color" > {{item.icon}} </v-icon>
                        {{ item.text }}
                        </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  </v-menu>
                </div>
                -->

              </div>

              <br>
              <!--
              <h5 v-if="!showArchivo" style="color:blue">Resultados de la búsqueda</h5>
              -->
            </div>
            <table id="myitems" class="table table-sm text-center">
              <thead>
                <tr style="text-align:center">
                  <td><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                  <td></td>
                  <td><b>Clase documento</b></td>
                  <td><b>Año</b></td>
                  <td><b>Consecutivo</b></td>
                  <td v-if="archiveList"><b>Tabla</b></td>
                  <td v-if="!archiveList"></td>
                  <td v-if="!archiveList"><b>Fecha seguimiento</b></td>
                  <td><b></b></td>
                  <td v-if="!archiveList"><b>Entidad</b></td>
                  <td v-if="!archiveList"><b>Ciudad</b></td>
                  <td><b>Asunto / Expediente</b></td>
                  <td><b>Tomo</b></td>
                  <td v-if="!archiveList"><b>Fecha Radicación</b></td>
                  <td v-if="!archiveList"><b>Tiempo respuesta</b></td>
                  <td v-if="!archiveList"><b>Tiempo restante</b></td>
                  <td><b>Dependencia</b></td>
                  <td><b>Serie</b></td>
                  <td v-if="!archiveList"><b>Anexos</b></td>
                  <td v-if="archiveList"><b>Desde</b></td>
                  <td v-if="archiveList"><b>Hasta</b></td>
                  <td v-if="archiveList"><b>Folios</b></td>
                  <td><b>Usuario</b></td>
                  <td><b>Archivado</b></td>
                  <td></td>
                  <!--
                  <td><b>Fecha documento</b></td>
                  <td>Id</td>
                  <td>Expediente</td>
                  <td>Relación</td>
                -->
                </tr>
              </thead>
              <tbody>
              <template v-for="(item, index) in items">
                <!--
                <tr v-on:contextmenu.prevent="showMenuContext($event, { 'item': item, index: index })" v-on:click.prevent="selectRow($event, index)" v-on:dblclick="$router.replace({ name: 'CreateDoc', params: {id: item._id, add: false} });" v-bind:class="{ selected: selectedRow === index }">
                -->
                <tr v-bind:id="'row' + index" v-on:contextmenu.prevent="showMenuContext($event, { 'item': item, 'index': index })" v-on:dblclick="openItem(item._id, false)" v-bind:class="classObject(item._id)">
                  <td><input type="checkbox" v-bind:value="item._id" v-model="delItems"></td>
                  <td v-bind:class="{ subitem: item.relacion }"><a v-if="item.relacion" href="#" @click.prevent="expediente(index)" v-bind:style="{ color: item.color }"><plus-icon class="custom-class" v-if="!item.show&&!item.refresh"></plus-icon><minus-icon class="custom-class" v-if="item.show&&!item.refresh"></minus-icon></a><img src="/images/loading_exp.gif" v-if="item.refresh" /></td>
                  <td v-bind:class="{ nuevo: item.notificar }"><a href="#" v-on:click="openItem(item._id, false)" v-bind:style="{ color: item.color }">{{ item.tcr_nmbre }}</a></td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_ano }}</td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">
                    {{ item.dcm_cdgo }}
                    <br>
                    <i v-if="item.show&&item.star" class="fa fa-star" style="font-size:12px;color:orangered" title="Principal"></i>
                  </td>
                  <td v-if="archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.tta_nmbre }}</td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">
                    <alert-circle-icon v-if="item.dcm_rspsta" class="custom-class"></alert-circle-icon>
                    <i v-if="item.dcm_inbox_email" class="far fa-envelope" style="font-size:16px;"></i>
                    <i v-if="item.dcm_web" class="fas fa-cloud" style="font-size:16px;"></i>
                  </td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">
                    {{ item.Fecha | formatDate }}
                    <div v-if="item.notificar">
                      <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
                    </div>
                  </td>
                  <td v-bind:class="{ nuevo: item.notificar }">
                    <a href="#" v-bind:style="{ color: item.color }">
                      <git-commit-icon class="custom-class" @click="consultarDetalles(items, index)"></git-commit-icon>
                    </a>
                    <v-menu :value="shown" :close-on-content-click="false" offset-y v-if="item.adjuntos.length > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <a href="#" v-bind:style="{ color: item.color }" v-bind="attrs" v-on="on" @click="listarAdjuntos(item.adjuntos)">
                          <i class="fas fa-paperclip"></i>
                        </a>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(a, x) in files"
                          :key="x"
                        >
                          <v-list-item-title>
                            <span v-if="isPdf(a.adj_nmbre)">
                              <a href="#" @click="viewPdf(item._id, files, x)">
                                <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                              </a>
                              <img v-if="a.download" src="/images/loading.gif" />
                            </span>
                            <span v-if="!isPdf(a.adj_nmbre)">                            
                              <a href="#" @click="downloadFile(item._id, files, x)">
                                <i class="fas fa-download" style="font-size:18px;color:blue"></i>
                              </a>                                                          
                              <v-progress-circular
                                v-if="a.download"
                                :rotate="-90"
                                :size="30"
                                :width="3"
                                :value="a.downloadPercentage"
                                color="primary"
                              >
                                {{ a.downloadPercentage }}
                              </v-progress-circular>
                            </span>
                            <span>
                              {{ a.adj_nmbre }}
                            </span>                            
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="files.length > 1">
                          <v-list-item-title>
                            <span>
                              <a href="#" @click="downloadAllFiles(item, files, allfiles)">
                                <i class="fas fa-angle-double-down"></i>
                                <span style="font-size:12px">
                                  Descargar todo
                                </span>
                              </a>
                              <v-progress-circular
                                v-if="allfiles.download"
                                :rotate="-90"
                                :size="30"
                                :width="3"
                                :value="allfiles.downloadPercentage"
                                color="primary"
                              >
                                {{ allfiles.downloadPercentage }}
                              </v-progress-circular>
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item.dcm_entidad">
                    {{ reduceText(item.dcm_entidad, 80) }}                
                  </td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_ciudad }}</td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item.dcm_asunto">
                    {{ reduceText(item.dcm_asunto, 80) }}                
                  </td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">
                    {{ item.dcm_tomo }}
                  </td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_fcharad | formatDate }}</td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }"><p style="text-align:center">{{ item.dcm_tres }}</p></td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }"><p style="text-align:center">{{ item.restante }}</p></td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item.dep_nmbre">{{ reduceText(item.dep_nmbre, 50) }}</td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item.ser_nmbre">{{ reduceText(item.ser_nmbre, 50) }}</td>
                  <td v-if="!archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_anexos }}</td>
                  <td v-if="archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_fchaini | formatDateUtc }}</td>
                  <td v-if="archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_fchafin | formatDateUtc }}</td>
                  <td v-if="archiveList" v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.dcm_nflios }}</td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">{{ item.usu_nmbre }}</td>
                  <td v-bind:class="{ nuevo: item.notificar }" v-bind:style="{ color: item.color }">
                    <div v-if="item.dcm_archivado" class="archivadocss">
                        <i class="fa fa-archive"></i> Sala: {{ item.sal_nmbre }} // {{ item.tpa_nmbre }} // Caja: {{ item.caj_cdgo }} // Inventario: {{ item.lgj_nmro }}
                    </div>
                  </td>
                  <td>
                    <a href="#" v-if="showHistoryButton" v-on:click.prevent="viewHistory(item)" title="Historial">
                      <i class="fas fa-history"></i>
                    </a>
                  </td>
                </tr>
                <tr v-for="(item2, index2) in item.expediente_docs" v-on:contextmenu.prevent="showMenuContext($event, { 'item': item2, 'index': index2, 'subitem': true })" v-on:dblclick="openItem(item2._id, false)" v-if="item.show && String(item2._id) != String(item._id)" v-bind:class="{ subitem: true, selected: selectedRow == item2._id }">
                  <td v-bind:style="{ 'background-color': 'white' }"><input type="checkbox" v-bind:value="item2._id"></td>
                  <td v-bind:style="{ 'background-color': 'white' }"></td>
                  <td><a href="#" v-on:click="openItem(item2._id, false)" v-bind:style="{ color: item2.color }">{{ tpocorName(item2.tcr_id) }}</a></td>
                  <td v-bind:style="{ color: item2.color }">{{ item2.dcm_ano }}</td>
                  <td v-bind:style="{ color: item2.color }">
                    {{ item2.dcm_cdgo }}
                    <br>
                    <i v-if="item.show&&item2.star" class="fa fa-star" style="font-size:12px;color:orangered" title="Principal"></i>
                  </td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }">
                    <alert-circle-icon v-if="item2.dcm_rspsta" class="custom-class"></alert-circle-icon>
                    <i v-if="item2.dcm_inbox_email" class="far fa-envelope" style="font-size:16px;"></i>
                    <i v-if="item2.dcm_web" class="fas fa-cloud" style="font-size:16px;"></i>
                  </td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }">
                    {{ item2.Fecha | formatDate }}
                    <div v-if="item2.notificar">
                      <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
                    </div>
                  </td>
                  <td>
                    <a href="#" v-bind:style="{ color: item2.color }">
                      <git-commit-icon class="custom-class" @click="consultarDetalles(item.expediente_docs, index2)"></git-commit-icon>
                    </a>
                    <v-menu :value="shown" :close-on-content-click="false" offset-y v-if="item2.adjuntos.length > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <a href="#" v-bind:style="{ color: item2.color }" v-bind="attrs" v-on="on" @click="listarAdjuntos(item2.adjuntos)">
                          <i class="fas fa-paperclip"></i>
                        </a>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(a2, x2) in files"
                          :key="x2"
                        >
                          <v-list-item-title>
                            <span v-if="isPdf(a2.adj_nmbre)">
                              <a href="#" @click="viewPdf(item2._id, files, x2)">
                                <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                              </a>
                              <img v-if="a2.download" src="/images/loading.gif" />
                            </span>
                            <span v-if="!isPdf(a2.adj_nmbre)">                            
                              <a href="#" @click="downloadFile(item2._id, files, x2)">
                                <i class="fas fa-download" style="font-size:18px;color:blue"></i>
                              </a>                                                          
                              <v-progress-circular
                                v-if="a2.download"
                                :rotate="-90"
                                :size="30"
                                :width="3"
                                :value="a2.downloadPercentage"
                                color="primary"
                              >
                                {{ a2.downloadPercentage }}
                              </v-progress-circular>
                            </span>
                            <span>
                              {{ a2.adj_nmbre }}
                            </span>                            
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <span v-if="files.length > 1">
                              <a href="#" @click="downloadAllFiles(item2, files, allfiles)">
                                <i class="fas fa-angle-double-down"></i>
                                <span style="font-size:12px">
                                  Descargar todo
                                </span>
                              </a>
                              <v-progress-circular
                                v-if="allfiles.download"
                                :rotate="-90"
                                :size="30"
                                :width="3"
                                :value="allfiles.downloadPercentage"
                                color="primary"
                              >
                                {{ allfiles.downloadPercentage }}
                              </v-progress-circular>
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item2.dcm_entidad">
                    {{ reduceText(item2.dcm_entidad, 80) }}
                  </td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_ciudad }}</td>
                  <td v-bind:style="{ color: item2.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item2.dcm_asunto">
                    {{ reduceText(item2.dcm_asunto, 80) }}
                  </td>
                  <td v-bind:style="{ color: item2.color }">{{ item2.dcm_tomo }}</td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_fcharad | formatDate }}</td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }"><p style="text-align:center">{{ item2.dcm_tres }}</p></td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }"><p style="text-align:center">{{ item2.restante }}</p></td>
                  <td v-bind:style="{ color: item2.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item2.dep_nmbre">{{ reduceText(item2.dep_nmbre, 50) }}</td>
                  <td v-bind:style="{ color: item2.color }" data-toggle="tooltip" data-placement="left" v-bind:title="item2.ser_nmbre">{{ reduceText(item2.ser_nmbre, 50) }}</td>
                  <td v-if="!archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_anexos }}</td>
                  <td v-if="archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_fchaini | formatDateUtc }}</td>
                  <td v-if="archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_fchafin | formatDateUtc }}</td>
                  <td v-if="archiveList" v-bind:style="{ color: item2.color }">{{ item2.dcm_nflios }}</td>
                  <td v-bind:style="{ color: item2.color }">{{ item2.usu_nmbre }}</td>
                  <td v-bind:style="{ color: item2.color }">
                    <div v-if="item2.dcm_archivado" class="archivadocss">
                      <i class="fa fa-archive"></i> Sala: {{ item2.sal_nmbre }} // {{ item2.tpa_nmbre }} // Caja: {{ item2.caj_cdgo }} // Inventario: {{ item2.lgj_nmro }}
                    </div>
                  </td>
                  <td>
                    <a href="#" v-if="showHistoryButton" v-on:click.prevent="viewHistory(item2)" title="Historial">
                      <i class="fas fa-history"></i>
                    </a>
                  </td>
                </tr>
                <!--
                <tr v-if="item.show" v-for="(item2, index2) in item.padre_docs" @contextmenu.prevent="$refs.menu.open($event, { 'item': item2, index: index2 })" v-bind:style="{ 'background-color': '#FCF3CF' }">
                  <td v-bind:style="{ 'background-color': 'white' }"><input type="checkbox" v-bind:value="item2._id"></td>
                  <td v-bind:style="{ 'background-color': 'white' }"></td>
                  <td><a href="#" v-on:click="$router.replace({ name: 'CreateDoc', params: {id: item2._id, add: false} });">{{ tpocorName(item2.tcr_id) }}</a></td>
                  <td>{{ item2.dcm_ano }}</td>
                  <td>{{ item2.dcm_cdgo }}</td>
                  <td v-bind:style="{ backgroundColor: item2.color }"></td>
                  <td>{{ item2.dcm_fcharad | formatDate}}</td>
                  <td>{{ item2.dcm_entidad }}</td>
                  <td>{{ item2.dcm_ciudad }}</td>
                  <td>{{ item2.dcm_asunto }}</td>
                  <td>{{ item2.dcm_anexos }}</td>
                  <td>{{ item2.dcm_fchaini | formatDate2}}</td>
                  <td>{{ item2.dep_cdgo }}</td>
                  <td></td>
                  <td>{{ item2.usu_cdgo }}</td>
                  <td>{{ item2._id }}</td>
                  <td>{{ item2.dcm_expediente }}</td>
                  <td>{{ item2.dcm_rlcion }}</td>
                  <td></td>
                </tr>
              -->
              </template>
              </tbody>
            </table>
            <!--
            <infinite-loading :identifier="infiniteId" @infinite="searchItems(search)"></infinite-loading>
            -->
            <!--{{ search }}-->
            <div>
              <!--
              <a id="viewmore" href="#" v-on:click.prevent="viewMore" v-if="!showArchivo"><v-icon x-large>expand_more</v-icon>Ver más</a>
              -->
              <a href="#" v-on:click.prevent="irArriba()"><v-icon x-large>arrow_upward</v-icon>Ir arriba</a>
              <div class="row justify-content-around" align="center">
                <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
              </div>
            </div>
            <button v-if="showArchivo" class="btn btn-danger text-align-right" v-on:click="deleteItems" v-bind:disabled="!checkedItems||!showDcmnts">
              <delete-icon class="custom-class"></delete-icon>Eliminar
            </button>
          </div>
          <!-- END TABLAS DCMNTS -->
          <div class="form-group col-md-4" style="text-align:right" v-if="showEstantes||showCajas||showLegajos">                
            <select2ajax id="ent_id" v-model="ent_id" :url="'/entidades/select'" v-on:select="setEntidad" v-bind:disabled="searchingItems" placeholder="Buscar entidad" />
          </div>
          <!-- TABLA ESTANTES -->
          <div class="" v-if="showEstantes">
            <p class="path-text">{{ path }}</p>
            <h3>Estantes</h3>
            <a href="#" v-on:click.prevent="newEstante()" data-toggle="modal" data-target="#estanteModal" :style="styleArchiveAddButton(allowModify)"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
            <createestante v-on:add-item="addEstante(estante)" v-bind:estantesButtonText="estantesButtonText" v-bind:item="estante"></createestante>
            <table class="table table-bordered bgtable">
              <thead>
                <tr>
                  <td><input type="checkbox" v-model="allEstantesChecked" v-on:click="checkAllEstantes"></td>
                  <td>Estante No.</td>
                  <td>Bandejas</td>
                  <td>Cajas por bandeja</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in estantes" v-on:dblclick="openRow(i._id)">
                  <td><input type="checkbox" v-bind:value="i._id" v-model="delEstantes"></td>
                  <td align="right">{{ i.est_cdgo }}</td>
                  <td align="right">{{ i.est_bndjas }}</td>
                  <td align="right">{{ i.est_cjasxb }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editEstante(index)" data-toggle="modal" data-target="#estanteModal" v-if="allowModify"><edit-icon class="custom-class"></edit-icon></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
            <button class="btn btn-danger" v-on:click="deleteEstantes" v-bind:disabled="!checkedEstantes">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delEstantes}}-->
          </div>
          <!-- END TABLAS ESTANTES -->
          <!-- TABLA CAJAS -->
          <div class="" v-if="showCajas">
            <p class="path-text">{{ path }}</p>
            <h3>Cajas</h3>
            <a href="#" v-on:click.prevent="newCaja()" data-toggle="modal" data-target="#cajaModal" :style="styleArchiveAddButton(allowModify)"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
            <createcaja v-on:add-item="addCaja(caja)" v-bind:cajasButtonText="cajasButtonText" v-bind:estantes="estantes_caj" v-bind:add="addCaj" v-bind:item="caja"></createcaja>
            <table class="table table-bordered bgtable">
              <thead>
                <tr>
                  <td><input type="checkbox" v-model="allCajasChecked" v-on:click="checkAllCajas"></td>
                  <td>Tipo de archivo</td>
                  <td>Caja No.</td>
                  <td>Nombre</td>
                  <td>Bandeja</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in cajas" v-on:dblclick="openRow(i._id)">
                  <td><input type="checkbox" v-bind:value="i._id" v-model="delCajas"></td>
                  <td align="right">{{ i.tpa_nmbre }}</td>
                  <td align="right">{{ i.caj_cdgo }}</td>
                  <td align="right">{{ i.caj_nmbre }}</td>
                  <td align="right">{{ i.caj_bndja }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editCaja(index)" data-toggle="modal" data-target="#cajaModal" v-if="allowModify"><edit-icon class="custom-class"></edit-icon></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
            <button class="btn btn-danger" v-on:click="deleteCajas" v-bind:disabled="!checkedCajas">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delCajas}}-->
          </div>
          <!-- END TABLAS CAJAS -->
          <!-- TABLA LEGAJOS -->
          <div class="" v-if="showLegajos">
            <p class="path-text">{{ path }}</p>
            <h3>Inventarios</h3>
            <a href="#" v-on:click.prevent="newLegajo()" data-toggle="modal" data-target="#legajoModal" :style="styleArchiveAddButton(allowModify)"><plus-circle-icon class="custom-class"></plus-circle-icon></a>
            <createlegajo v-on:add-item="addLegajo(legajo)" v-bind:legajosButtonText="legajosButtonText" v-bind:add="addLgj" v-bind:item="legajo"></createlegajo>
            <table class="table table-bordered bgtable">
              <thead>
                <tr>
                  <td><input type="checkbox" v-model="allLegajosChecked" v-on:click="checkAllLegajos"></td>
                  <td>Inventario No.</td>
                  <td>Nombre</td>
                  <td>Unidad de conservación</td>
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in legajos" v-on:dblclick="openRow(i._id)">
                  <td><input type="checkbox" v-bind:value="i._id" v-model="delLegajos"></td>
                  <td align="center">{{ i.lgj_nmro }}</td>
                  <td align="left">{{ i.lgj_nmbre }}</td>
                  <td align="left">{{ i.uco_nmbre }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editLegajo(index)" data-toggle="modal" data-target="#legajoModal" v-if="allowModify"><edit-icon class="custom-class"></edit-icon></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
            <button class="btn btn-danger" v-on:click="deleteLegajos3" v-bind:disabled="!checkedLegajos">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delLegajos}}-->
          </div>
          <!-- END TABLAS LEGAJOS -->
        </div>
        <div id="resizer"></div>
        <div id="left_panel" class="bgarchive">
          <v-treeview
            v-model="tree"
            :items="treeData"
            item-key="id"
            activatable
            :active.sync="active"
            :open.sync="open"
          >
            <template slot="prepend" slot-scope="{ item, open }">
              <v-icon v-if="item.lgj">
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
              <v-icon v-else>
                {{ types[item.type] }}
              </v-icon>
            </template>
          </v-treeview>
        </div>
      </div>   
    </div>  
    <div id="componentes">
      <advancedsearchdoc v-on:search="showQueries('A')" v-bind:item="search"></advancedsearchdoc>
      <archivardoc v-on:add-item="archivarDoc(ubicacion)" v-bind:item="ubicacion"></archivardoc>
      <prestardoc v-on:add-item="prestarDoc(solicitud)" v-bind:item="solicitud"></prestardoc>
      <timeline :detalles="detalles" :items="detalles_items"></timeline>
      <viewpdf v-bind:pdfFile="pdfFile"></viewpdf>
      <vue-context ref="menu">
        <ul slot-scope="child" v-if="child.data">
          <li id="respuesta" @click="onClick($event.target.id, child.data)" v-if="child.data.allowResponse">Agregar respuesta<mail-icon class="custom-class"></mail-icon></li>
          <li id="relacion" @click="onClick($event.target.id, child.data)" v-if="child.data.allowRelation">Agregar documento relacionado<package-icon class="custom-class"></package-icon></li>
          <li id="agrupar" @click="onClickAgrupar($event.target.id, child.data)" v-if="child.data.allowGrouping">Relacionar documentos seleccionados<package-icon class="custom-class"></package-icon></li>
          <li id="desagrupar" @click="onClickDesagrupar($event.target.id, child.data)" v-if="child.data.allowUngrouping">Eliminar de la relación<package-icon class="custom-class"></package-icon></li>
          <li id="archivar" @click="onClickArchivar($event.target.id, child.data)" v-if="child.data.allowArchiving">Archivar documentos seleccionados<archive-icon class="custom-class"></archive-icon></li>
          <li id="desarchivar" @click="onClickDesarchivar($event.target.id, child.data)" v-if="child.data.allowUnarchiving">Desarchivar documento</li>
          <li id="prestar" @click="onClickPrestar($event.target.id, child.data)" v-if="child.data.allowLending">Prestar documentos seleccionados</li>
        </ul>
      </vue-context>    
    </div>
  </div>
</template>

<script>
/*
import { VIcon, VProgressLinear, VTreeview } from 'vuetify/lib';
*/

import ModalForm from '../modal/ModalForm.vue';

import moment from 'moment';
import { formatDate, formatDateUtc } from './../../plugins/filters';

import XLSX from 'xlsx';

import AdvancedSearchDoc from './AdvancedSearchDoc.vue';
import { VueContext } from 'vue-context';
import { SearchIcon, ArrowLeftIcon, PlusCircleIcon, EditIcon, DeleteIcon, MailIcon, PackageIcon, PlusIcon, MinusIcon, ArchiveIcon, AlertCircleIcon, TargetIcon, RefreshCwIcon, GitCommitIcon } from 'vue-feather-icons';
import CreateEstante from './../estante/CreateEstante.vue';
import CreateCaja from './../caja/CreateCaja.vue';
import CreateLegajo from './../legajo/CreateLegajo.vue';
import ArchivarDoc from './ArchivarDoc.vue';
import PrestarDoc from './PrestarDoc.vue';
import ViewPdf from './ViewPdf.vue';

//import CreateDoc from './CreateDoc.vue';

import { wait } from '../../utils';

//import InfiniteLoading from 'vue-infinite-loading';

import Timeline from './Timeline.vue';
import { tiempoUtilizado } from '../../utils';

import select2ajax from '../select2/Select2Ajax.vue';
import control_sheet_excel from '../../plugins/FormatoReports/control_sheet/excel'

export default {
  components: {
    ModalForm,
    //VIcon, VProgressLinear, VTreeview,
    'advancedsearchdoc': AdvancedSearchDoc,
    //'createdoc': CreateDoc,
    VueContext,
    SearchIcon, ArrowLeftIcon, PlusCircleIcon, EditIcon, DeleteIcon, MailIcon, PackageIcon, PlusIcon, MinusIcon, ArchiveIcon, AlertCircleIcon, TargetIcon, RefreshCwIcon, GitCommitIcon,
    'createestante': CreateEstante,
    'createcaja': CreateCaja,
    'createlegajo': CreateLegajo,
    'archivardoc': ArchivarDoc,
    'prestardoc': PrestarDoc,
    //InfiniteLoading,
    'timeline': Timeline,
    'viewpdf': ViewPdf,
    select2ajax
  },
  data(){
    return{
      menu_control_sheet: [
        {value: 'pdf', text: 'Pdf', icon: 'mdi-file-pdf', color: 'red darken-2'},
        {value: 'excel', text: 'Excel', icon: 'mdi-file-excel', color: 'green darken-2'}
      ],
      menu_control_sheet_v2: [
        {value: 'edit', text: 'Editar', icon: 'mdi-file-table-outline', color: 'green darken-2'},
        {value: 'pdf', text: 'Pdf', icon: 'mdi-file-pdf', color: 'red darken-2'}
      ],
      debug: '',
      items: [],
      tpocors: [],
      tpotablas: [],
      tpodocs: [],
      tpoarchs: [],
      usuarios: [],
      dependencias: [],
      series: [],
      uxrs: [],
      uconservs: [],
      estados: [],
      tcr_id: null,
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      selectedRow: '', //Row selected in table
      showDoc: false,
      showArchivo: false,
      showDcmnts: true,
      showEstantes: false,
      showCajas: false,
      showLegajos: false,
      archiveList: false,
      salas: [],
      estantes: [],
      estantes_caj: [],
      cajas: [],
      legajos: [],
      count: 100,
      treeData: [],
      selectedNode: null,
      contextMenuItems: [{code: 'DELETE_NODE', label: 'Eliminar nodo'}, {
          code: 'ADD_CHILD_NODE',
          label: 'Agregar item'
      }, {code: 'RENAME_NODE', label: 'Renombrar'}],
      estante: {},
      estantesButtonText: 'Agregar estante',
      addEst: true,
      editEst: null,
      delEstantes: [], //Estantes seleccionados
      allEstantesChecked: false, //Check all estantes
      caja: {},
      cajasButtonText: 'Agregar caja',
      addCaj: true,
      editCaj: null,
      delCajas: [], //Estantes seleccionados
      allCajasChecked: false, //Check all estantes
      legajo: {},
      legajosButtonText: 'Agregar legajo',
      addLgj: true,
      editLgj: null,
      delLegajos: [], //Estantes seleccionados
      allLegajosChecked: false, //Check all estantes
      ubicacion: {},
      allowModify: false,
      showProgress: false,
      can_add: false,
      searchingItems: false,
      numDias: 0,
      skipItems: 0,
      limitItems: 50,
      rangeItems: 50,
      infiniteId: +new Date(),
      mouse: 0,
      searchLastTime: +new Date(),
      path: '',
      active: [],
      open: [],
      tree: [],
      types: {
        arc: 'mdi-archive',
        sal: 'mdi-hospital-building',
        est: 'mdi-grid',
        caj: 'mdi-package-variant',
        lgj: 'mdi-folder'
      },
      x: 0,
      y: 0,
      isResizing: false,
      lastDownX: 0,
      right: 0,
      width: 0,
      offsetRight: 0,
      detalles: {},
      detalles_items: [],
      difScrollData: 0,
      newData: false,
      usuarioSegResponde: false,
      solicitud: {},
      dnl: [],
      pdfFile: {},
      timestamp: 0,
      url: '',
      shown: false,
      files: [],
      allfiles: {},
      showHistoryButton: false,
      ent_id: null,
      modalMsg: ''
    }
  },
  filters: {
    formatDate, formatDateUtc
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    },
    checkedEstantes(){
      if (this.delEstantes.length > 0){
        return true;
      } else {
        return false;
      }
    },
    checkedCajas(){
      if (this.delCajas.length > 0){
        return true;
      } else {
        return false;
      }
    },
    checkedLegajos(){
      if (this.delLegajos.length > 0){
        return true;
      } else {
        return false;
      }
    },
    selected(){
      this.path = '';
      if (!this.active.length) return undefined

      this.showDcmnts = false;
      this.showEstantes = false;
      this.showCajas = false;
      this.showLegajos = false;

      const id = this.active[0];
      var node = this.searchTreeById(this.treeData[0], id);

      if (node){
        var type = node.type;

        if (type == 'arc'){
          //return 'Archivo';
        } else if (type == 'sal'){
          this.showEstantes = true;
          for (var i = 0; i < this.salas.length; i++){
            if (id == this.salas[i]._id){
              this.allowModify = this.salas[i].uxs_mdfcr;
              i = this.salas.length;
            }
          }
          this.getEstantes(id);
        } else if (type == 'est'){
          this.showCajas = true;
          this.getCajas(id);
        } else if (type == 'caj'){
          this.showLegajos = true;
          this.getLegajos(id);
        } else if (type == 'lgj'){
          this.showDcmnts = true;
          this.getDcmnts(id);
        }

        return node;
      }

    },
    archiveRoute(){

    }
  },
  watch: {
    // whenever mouseMove changes, this function will run
    mouseMove2: function () {
      if (this.mouseMove){
        this.mouse = 'Mouse is moving...';
      } else {
        this.mouse = '';
      }
    },
    selected: function () {

    }
  },
  created: async function()
  {
    $('[data-toggle="tooltip"]').tooltip();

    this.modalMsg = 'Por favor espere mientras se consultan los datos..';
    this.showProgress = true;      

    await this.fetchTimeStamp();
    await this.fetchTpocor();
    await this.fetchTpotabla();
    await this.fetchTpodoc();
    await this.fetchTpoarch();
    await this.fetchUconserv();
    await this.fetchEstado();
    await this.fetchDependencia();
    await this.fetchSerie();
    await this.fetchUsuario();
    await this.fetchUsuxrad();
    await this.fetchUsuxsal();
    await this.showHistory();

    let uri = '/uxr/can_add';
    await this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'usu_id': this.$store.state.user })
    .then((response) => {
      if (response.data.length > 0){
        this.can_add = true;
      } else {
        this.can_add = false;
      }
    });

    //alert(this.$route.params.search);
    if (this.$route.params.search != undefined && this.$route.params.search != null){
      this.search = this.$route.params.search;
      if (this.search.dcm_archivado){
        this.showArchivo = true;
        if (this.search?.sal_id) {
          let sala = this.salas.find(i => i._id == this.search?.sal_id);        
          this.allowModify = sala.uxs_mdfcr;
        }
        this.openNode();
      }
    } else {
      this.search = { today: true };
    }

    this.showProgress = false;      

    this.disableForm(true);
    this.searchItems(this.search);
  },
  mounted: function() {
    document.addEventListener('click', this.unselectRow);

    var container = document.getElementById('container');
    var element = document.getElementById('left_panel');
    var right_panel = document.getElementById('right_panel');
    var resizer = document.getElementById('resizer');

    resizer.addEventListener('mousedown', this.initResize, false);
    window.addEventListener('resize', this.ResizeWindow, false);

    right_panel.addEventListener('scroll', this.scrollData);
    //debugger;

    /*    
    var offsetLeft = 2 * element.clientLeft; //e.clientX - menu.clientWidth - container.offsetLeft - container.clientLeft;
    resizer.style.left = (offsetLeft) + 'px';
    element.style.width = (offsetLeft) + 'px';
    right_panel.style.width = (container.clientWidth - (offsetLeft) - 2 * resizer.clientLeft) + 'px';
    */

    element.style.width = '0px';
    right_panel.style.width = (container.clientWidth) + 'px';
    //resizer.style.left = (right_panel.offsetLeft) + 'px';

    if (this.$route.params.search != undefined && this.$route.params.search != null){
      this.search = this.$route.params.search;
      if (this.search.dcm_archivado){
        this.resizeArchivo(container, element, right_panel, resizer);
      }
    }

    /*
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mousedown', this.mouseMove);
    document.addEventListener('keypress', this.mouseMove);
    document.addEventListener('scroll', this.mouseMove);
    document.addEventListener('touchmove', this.mouseMove);
    */
    $('#myTab a').on('click', function(e){
      e.preventDefault();
      $(this).tab('show');
    });

    $('#R').tab('show');


  },
  beforeDestroy(){
    document.removeEventListener('click', this.unselectRow);

    //document.removeEventListener('mousemove');
    //document.removeEventListener('mouseup');

  /*
    document.removeEventListener('mousemove');
    document.removeEventListener('mousedown');
    document.removeEventListener('keypress');
    document.removeEventListener('scroll');
    document.removeEventListener('touchmove');
    */
  },
  methods: {
    initResize(e) {
      window.addEventListener('mousemove', this.Resize, false);
      window.addEventListener('mouseup', this.stopResize, false);
    },
    Resize(e) {
      var area = document.getElementById('area');
      var container = document.getElementById('container');
      var element = document.getElementById('left_panel');
      var right_panel = document.getElementById('right_panel');
      var menu = document.getElementById('left_menu');
      var resizer = document.getElementById('resizer');

      //debugger;

      var offsetLeft = e.clientX - area.offsetLeft - container.offsetLeft - container.clientLeft;
      if (this.$store.state.drawer){
        offsetLeft = offsetLeft - menu.clientWidth;
      }

      if (offsetLeft >= 2 * element.clientLeft && offsetLeft <= container.clientWidth - 2 * element.clientLeft - 2 * right_panel.clientLeft - 2 * resizer.clientLeft){
        // resizer.style.left = (offsetLeft) + 'px';
        element.style.width = (offsetLeft) + 'px';
        right_panel.style.width = (container.clientWidth - (offsetLeft) - 2 * resizer.clientLeft - 1) + 'px';
      }

    },
    stopResize(e) {
        window.removeEventListener('mousemove', this.Resize, false);
        window.removeEventListener('mouseup', this.stopResize, false);
    },
    ResizeWindow() {
      var area = document.getElementById('area');
      var container = document.getElementById('container');
      var element = document.getElementById('left_panel');
      var right_panel = document.getElementById('right_panel');
      var menu = document.getElementById('left_menu');
      var resizer = document.getElementById('resizer');
      
      if (this.showArchivo){
        this.resizeArchivo(container, element, right_panel, resizer);
      } else {
        if (element != null) element.style.width = '0px';
  
        if (right_panel != null) right_panel.style.width = (container.clientWidth) + 'px';
        if (resizer != null) resizer.style.left = (right_panel.offsetLeft) + 'px';
      }
    },
    resizeArchivo(container, element, right_panel, resizer){
      if (container.clientWidth < 800){
        container.style.overflow = 'visible';
        var offsetLeft = container.clientWidth;
        element.style.width = (container.clientWidth - 2 * resizer.clientLeft - 1) + 'px';
        right_panel.style.width = (container.clientWidth - 2 * resizer.clientLeft - 1) + 'px';
      } else {
        container.style.overflow = 'hidden';          
        var offsetLeft = container.clientWidth * 0.3;
        element.style.width = (offsetLeft) + 'px';
        right_panel.style.width = (container.clientWidth - (offsetLeft) - 2 * resizer.clientLeft - 1) + 'px';
      }
    },
    handle_onmousedown(e) {
        this.isResizing = true;
        this.lastDownX = e.clientX;
    },
    document_onmousemove(e) {
        // we don't want to do anything if we aren't resizing.
        if (!this.isResizing) {
            return;
        }
        var container = document.getElementById('container');
        var left = document.getElementById('left_panel');
        var right = document.getElementById('right_panel');
        var handle = document.getElementById('drag');
        var menu = document.getElementById('left_menu');
      
        var offsetRight = container.clientWidth + menu.clientWidth - (e.clientX - container.offsetLeft - menu.offsetLeft);
        this.offsetRight = offsetRight;

        left.style.right = offsetRight + "px"; 
        right.style.width = offsetRight + "px"; 
    },

    document_onmouseup(e) {
        // stop resizing
        this.isResizing = false;
    },

    mouseMove(event){
      if (!this.filter && this.showDcmnts){
        var time = new Date();
        var dif = time.getTime() - this.searchLastTime;
        this.mouse = dif;
        if (dif > 2*60*1000){
          /*
          var query = this.search;
          var d = new Date(this.items[0].Fecha);
          d.setTime( d.getTime() + 1 );
          query.Fechaini = d;
          this.searchItems(query, true, true);
          */
          this.searchItems(this.search, false, false);
        }
      }
    },
    scrollData(event){
      if (this.showDcmnts&&!this.showArchivo){
        var el = document.getElementById('right_panel');
        this.difScrollData = el.scrollTop + el.clientHeight + 10 - el.scrollHeight;
        if (this.difScrollData > 0){
          if (!this.newData){
            this.viewMore();
          }
        }
      }
    },
    irArriba(){
      document.getElementById('right_panel').scrollTop = 0;
    },
    disableForm(value) {
      /*
      var inputs = document.getElementsByTagName("input");
      for (var i = 0; i < inputs.length; i++) {
          inputs[i].disabled = value;
      }
      var selects = document.getElementsByTagName("select");
      for (var i = 0; i < selects.length; i++) {
          selects[i].disabled = value;
      }
      var textareas = document.getElementsByTagName("textarea");
      for (var i = 0; i < textareas.length; i++) {
          textareas[i].disabled = value;
      }
      var buttons = document.getElementsByTagName("button");
      for (var i = 0; i < buttons.length; i++) {
          buttons[i].disabled = value;
      }
      */
      this.searchingItems = value;
    },
    showOption(data){
      if (data.item.dcm_rspsta){
        return true;
      } else {
        return false;
      }
    },
    async fetchTpocor()
    {
      //alert(String(this.$store.state.company));
      let uri = '/tpocors/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
      });
    },
    async fetchTpotabla()
    {
      //alert(String(this.$store.state.company));
      let uri = '/tpotablas/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.tpotablas = response.data;
      });
    },
    async fetchTpodoc()
    {
      //alert(String(this.$store.state.company));
      let uri = '/tpodocs/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.tpodocs = response.data;
      });
    },
    async fetchTpoarch()
    {
      let uri = '/tpoarchs/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.tpoarchs = response.data;
      });
    },
    async fetchUsuario()
    {
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      await this.axios.get(uri).then((response) => {
        this.usuarios = response.data;
      });
    },
    async fetchUconserv()
    {
      let uri = '/uconservs/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.uconservs = response.data;
      });
    },
    async fetchEstado()
    {
      let uri = '/estados/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.estados = response.data;
      });
    },
    async fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
      });
    },
    async fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      await this.axios.get(uri).then((response) => {
        this.series = response.data;
      });
    },
    async fetchEntidad(obj, id){
      let uri = '/entidades/id/' + String(id);
      this.axios.get(uri)
      .then((response) => {
        this.$set(obj, 'dcm_entidad', response.data);
      })
      .catch((err) => {
        alert(err);
      });
    },
    async fetchUsuxrad()
    {
      let uri = '/uxr/usuario';
      await this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'usu_id': this.$store.state.user })
      .then((response) => {
        this.uxrs = response.data;
      });
    },
    async fetchUsuxsal(){
      let uri = '/uxs/salas';
      await this.axios.post(uri, { cmp_id: this.$store.state.company, usu_id: this.$store.state.user }).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.sal_nmbre.toLowerCase() > b.sal_nmbre.toLowerCase()) { return 1; };
          if (a.sal_nmbre.toLowerCase() < b.sal_nmbre.toLowerCase()) { return -1; };
          return 0;
        });
        this.salas = list;

      });
    },
    getEstantes(sala)
    {
      //alert('getEstantes');
      let uri = '/estantes/sala';
      this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: sala }).then((response) => {
        this.estantes = response.data;
        this.delEstantes = [];
  
        let node = this.searchTreeById(this.treeData[0], sala);
        node.children = [];

        for (let j = 0; j < this.estantes.length; j++){
          node.children.push({ id: this.estantes[j]._id, name: 'Estante ' + this.estantes[j].est_cdgo, type: 'est', children: [] });
        }
        this.path = this.pathTreeById(this.treeData[0], node);

      });
    },
    getCajas(estante)
    {
      let uri = '/cajas/estante';
      this.axios.post(uri, { cmp_id: this.$store.state.company, est_id: estante }).then((response) => {
        this.cajas = response.data;
        this.delCajas = [];

        let node = this.searchTreeById(this.treeData[0], estante);
        node.children = [];

        for (let j = 0; j < this.cajas.length; j++){
          for (let k = 0; k < this.tpoarchs.length; k++){
            if (this.cajas[j].tpa_id == this.tpoarchs[k]._id){
              this.cajas[j].tpa_nmbre = this.tpoarchs[k].tpa_nmbre;
              k = this.tpoarchs.length;
            }
          }

          let name = this.cajas[j].tpa_nmbre +  ' - Caja ' + this.cajas[j].caj_cdgo;
          if (this.cajas[j].caj_nmbre){
            name += ' - ' + this.cajas[j].caj_nmbre;
          }

          node.children.push({ id: this.cajas[j]._id, name: name, type: 'caj', children: [] });
        }
        this.path = this.pathTreeById(this.treeData[0], node);

      }).catch((err) => {
        //alert('Error al consultar las cajas. ' + err);
      });
    },
    getLegajos(caja)
    {
      this.modalMsg = 'Por favor espere mientras se consultan los datos..';
      this.showProgress = true;      
      let uri = '/legajos/caja';
      this.axios.post(uri, { cmp_id: this.$store.state.company, caj_id: caja }).then((response) => {
        this.legajos = response.data;
        this.delLegajos = [];

        let node = this.searchTreeById(this.treeData[0], caja);
        if (!node){
          //alert('Caja no encontrada.');
        }
        node.children = [];
        var counter = 0;
        if (this.legajos.length == 0){
          this.showProgress = false;
        }
        for (let j = 0; j < this.legajos.length; j++){
          for (let k = 0; k < this.uconservs.length; k++){
            if (this.legajos[j].uco_id == this.uconservs[k]._id){
              this.legajos[j].uco_nmbre = this.uconservs[k].uco_nmbre;
              k = this.uconservs.length;
            }
          }
          let name = 'Inventario ' + this.legajos[j].lgj_nmro;
          if (this.legajos[j].lgj_nmbre){
            name += ' - ' + this.legajos[j].lgj_nmbre;
          }

          node.children.push({ id: this.legajos[j]._id, name: name, type: 'lgj', lgj: '1', children: [] });

          /** Eliminar si se consulta el contenido */
          counter++;
          if (counter == this.legajos.length){
            this.showProgress = false;            
            this.path = this.pathTreeById(this.treeData[0], node);
          }
          /** END */

          /*
          let uri = '/docs/contenido';
          this.axios.post(uri, { index: j, cmp_id: this.$store.state.company, sal_id: this.legajos[j].sal_id, tpa_id: this.legajos[j].tpa_id, caj_cdgo: this.legajos[j].caj_cdgo, lgj_nmro: this.legajos[j].lgj_nmro })
          .then((response) => {
            let m = response.data.index;
            let contenido = '';
            if (response.data.dcmnts[0].dcm_asunto != undefined){
              contenido = response.data.dcmnts[0].dcm_asunto;
            }
            if (response.data.dcmnts[0].dcm_tomo != undefined){
              contenido += ' - Tomo: ' + response.data.dcmnts[0].dcm_tomo;
            }
            for (let i = 1; i < response.data.dcmnts.length; i ++){
              if (response.data.dcmnts[i].dcm_asunto != undefined){
                contenido += ' // ' + response.data.dcmnts[i].dcm_asunto;
              }
              if (response.data.dcmnts[i].dcm_tomo != undefined){
                contenido += ' - Tomo: ' + response.data.dcmnts[i].dcm_tomo;
              }
            }
            if (contenido.length > 300){
              contenido = contenido.substr(0, 300).concat('....');
            }
            this.legajos[m].contenido = contenido;
            this.$set(this.legajos, m, this.legajos[m]);
            node.children[m].name = 'Inventario ' + this.legajos[m].lgj_nmro;
            if (contenido != ''){
              node.children[m].name += ' - ' + contenido;
            }
            counter++;
            if (counter == this.legajos.length){
              this.showProgress = false;
              this.path = this.pathTreeById(this.treeData[0], node);
            }
          }).catch((err) => {
            counter++;
            if (counter == this.legajos.length){
              this.showProgress = false;
              this.path = this.pathTreeById(this.treeData[0], node);
            }
          });
          */
        }
      }).catch((err) => {
        //alert('Error al consultar los legajos. ' + err);
        this.showProgress = false;
      });
    },
    getDcmnts(legajo)
    {
      this.modalMsg = 'Por favor espere mientras se consultan los datos..';
      this.showProgress = true;
      let uri = '/legajos/ubicacion/' + legajo;
      this.axios.get(uri).then((response) => {
        let data = response.data;
        this.$store.commit('setOpen', this.open);
        this.$store.commit('setActive', this.active);
        this.$store.commit('setTreeData', this.treeData);
        this.path = this.pathTreeById(this.treeData[0], legajo);

        this.search = { sal_id: data.sal_id, tpa_id: data.tpa_id, caj_cdgo: data.caj_cdgo, lgj_nmro: data.lgj_nmro, dcm_archivado: true };
        this.searchItems(this.search);
      }).catch((err) => {
        this.showProgress = false;
        this.message = '¡Error al buscar los documentos del inventario. ' + err;
      });
    },
    archivarDoc(ubicacion){
      this.modalMsg = 'Por favor espere mientras se actualizan los datos..';
      this.showProgress = true;

      var t = null;
      for (let i = 0; i < this.items.length; i++){
        if (this.delItems[0] == this.items[i]._id){
          t = this.items[i];
          i = this.items.length;
        }
      }

      let uri = '/docs/legajo';
      this.axios.post(uri, ubicacion)
      .then((response) => {
        let items = response.data;

        for (let i = 0; i < items.length; i++){
          if (items[i].dep_id != t.dep_id || items[i].ser_id != t.ser_id){
            alert('Los documentos actuales archivados en esa ubicación no son de la misma dependencia y serie que los seleccionados. Revise los documentos seleccionados y vuelva a intentarlo.');
            this.showProgress = false;
            return;
          }
        }

        //+++++++++++++++++++++++++++
        var counter = 0;
        for (let i = 0; i < this.delItems.length; i++){
          // Archivar documentos
          let uri = '/docs/archivar';
          this.axios.post(uri, { 'item': this.delItems[i], 'ubicacion': ubicacion })
          .then((response) => {
            counter++;
            if (counter === this.delItems.length){
              //alert('Archivado');
              this.searchItems(this.search);
            }
          })
          .catch((err) => {
            this.message = 'Error al actualizar la ubicación en el archivo.' + err;
            alert(this.message);
            this.showProgress = false;
          });
        }


        //+++++++++++++++++++++++++++
      })
      .catch((err) => {
        this.message = 'Error al consultar los documentos de la ubicación en el archivo.' + err;
        alert(this.message);
        this.showProgress = false;
        return;
      });

    },
    prestarDoc(solicitud){
      solicitud.cmp_id = String(this.$store.state.company);
      solicitud.usu_id = String(this.$store.state.user);
      // Prestar documentos
      let uri = '/docs/prestar';
      this.axios.post(uri, { 'items': this.delItems, 'solicitud': solicitud })
      .then((response) => {
        alert(response.data.msg);
      })
      .catch((err) => {
        this.message = err.response.data.msg;
        alert(this.message);
      });
    },
    openArchive(id){
      // Archivar documentos
      let uri = '/docs/archivo';
      this.axios.post(uri, { '_id': id })
      .then((response) => {

      })
      .catch((err) => {
        this.message = 'Error al abrir la ubicación en el archivo.' + err;
        alert(this.message);
      });
    },
    tpocorName(k){
      for (var i = 0; i < this.tpocors.length; i++){
        if (String(k) == String(this.tpocors[i]._id)){
          return this.tpocors[i].tcr_nmbre;
        }
      }
      return '';
    },
    tabName(k){
      return 'list-'.concat(String(k));
    },
    addItem(){
      var params = {};
      params.add = true;

      if (this.showArchivo && this.showDcmnts){
        this.search.dcm_archivado = true;
        for (var j = 0; j < this.tpocors.length; j++){
          if (this.tpocors[j].tcr_cdgo == 'O'){
            params.tcr_id = this.tpocors[j]._id;
            j = this.tpocors.length;
          }
        }
        for (var j = 0; j < this.tpodocs.length; j++){
          if (this.tpodocs[j].tdo_cdgo == 1){
            params.tdo_id = this.tpodocs[j]._id;
            j = this.tpodocs.length;
          }
        }
        if (this.items.length > 0){
          params.dep_id = this.items[0].dep_id;
          params.ser_id = this.items[0].ser_id;
        }
      }
      params.search = this.search;
      this.$router.replace({ 'name': 'CreateDoc', 'params': params });
    },
    fetchItems()
    {
      let uri = '/docs';
      this.axios.get(uri).then((response) => {
        this.items = response.data;
        this.delItems = [];
      });
    },
    openItem(id, add){
      //this.showDoc = true;
      this.$router.replace({ name: 'CreateDoc', params: { 'id': id, 'add': add, 'search': this.search } }).catch(() => true);
    },
    closeItem(){
      this.showDoc = false;
      alert('Cerrando item');
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        var user = String(this.$store.state.user);
        let uri = '/docs/delete/'+id + '/' + user;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    hayCorrespondencia(){
      // Buscar correspondencia ++++++++++++++++
      for (let j = 0; j < this.delItems.length; j++){
        for (let k = 0; k < this.items.length; k++){
          if (this.delItems[j] == this.items[k]._id){
            for (let l = 0; l < this.tpocors.length; l++){
              if (this.items[k].tcr_id == this.tpocors[l]._id){
                if (this.tpocors[l].tcr_corr){
                  alert('Algunos de los documentos seleccionados son documentos de correspondencia y no se pueden eliminar.');
                  return true;
                }
                l = this.tpocors.length;
              }
            }
            k = this.items.length;
          }
        }
      }
      return false;
      // ++++++++++++++++++++++
    },
    deleteItems(){
      if (this.delItems.length > 0){
        if (!this.hayCorrespondencia()){
          const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
          if (response) {
            var user = String(this.$store.state.user);

            for (var i = 0; i < this.delItems.length; i++){
              //alert('Item(' + i + '): ' + this.delItems[i]);
              let uri = '/docs/delete/' + this.delItems[i] + '/' + user;
              this.axios.get(uri)
              .then(res => {
                //this.items.splice(index, 1);
                if (this.search){
                  this.searchItems(this.search);
                }
              })
              .catch(err => {
                console.log(err);
                for (var m = 0; m < this.items.length; m++){
                  if (this.delItems[i] == this.items[m]._id){
                    alert('No se ha podido eliminar el item ' + this.items[m]);
                    m = this.items.length;
                  }
                }
              });
            }
            /*
            if (this.filter){
              this.searchItems({ dcm_entidad: this.keywords });
            } else {
              //alert(this.tcr_id);
              this.searchItems({ tcr_id: this.tcr_id });
            }
            */
          }
        }
      }
      return;
    },
    itemChecked(id){
      for (var i = 0; i < this.delItems.length; i++){
        if (id == this.delItems[i]){
          return true;
        }
      }
      return false;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    viewMore(){
      /*
      var now = new Date();
      var Fechafin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

      var nd = new Date(Fechafin);
      this.numDias += 2;
      nd.setDate(nd.getDate() - this.numDias); // Next Day
      var Fechaini = nd;

      this.searchItems({ 'Fechaini': Fechaini, 'Fechafin': Fechafin });
      */
      //this.searchItems({ today: true });
      if (this.items.length > 0){
        this.newData = true;
        this.searchItems(this.search, true, false);
      }
    },
    async revisarRespuestas(list, callback){
      var existeRespuesta = false;
      for (var i = 0; i < list.length; i++){
        if (list[i].dcm_rspsta){
          existeRespuesta = true;
          break;
        }
      }

      var dnl = [];

      if(existeRespuesta){
        var p = {};
        p.cmp_id = this.$store.state.company;

        var uri = '/dianols/total';
        await this.axios.post(uri, p)
        .then((response) => {
          dnl = response.data;
          return callback(dnl);
        }).catch((err) => {
          alert('Error al consultar los días no laborales. ' + err);
          return callback(dnl);
        });
      } else {
        return callback(dnl);        
      }
    },
    async searchItems(t, expand, update, $state){
      if (t != null){
        var p = {};

        // Buscar múltiples radicados
        if (t.keys == null){
          p = t;
        } else {
          p = {};
          let pk = t.keys.substring(0,4).toLowerCase();
          //alert(pk);
          if (pk == 'rad:'){
            let rad = t.keys.substring(4).split('+');
            if (rad.length > 0){
              var tcr_id = null;
              var dcm_ano = 0;
              var dcm_cdgo = 0;
              var b = [];
              p['$or'] = [];

              for (var i = 0; i < rad.length; i++){
                b = rad[i].split('-');
                if (b.length == 3){
                  tcr_id = null;
                  for (var j = 0; j < this.tpocors.length; j++){
                    if (b[0].toLowerCase() == this.tpocors[j].tcr_cdgo.toLowerCase()){
                      tcr_id = this.tpocors[j]._id;
                      j = this.tpocors.length;
                    }
                  }
                  dcm_ano = parseInt(b[1]);
                  dcm_cdgo = parseInt(b[2]);

                  if (tcr_id != null && dcm_ano > 0 && dcm_cdgo > 0){
                    //p['$or'].push({ $and: [{ tcr_id: tcr_id }, { dcm_ano: dcm_ano }, { dcm_cdgo: dcm_cdgo }] });
                    p['$or'].push({ tcr_id: tcr_id, dcm_ano: dcm_ano, dcm_cdgo: dcm_cdgo });
                  } else {
                    alert('Los códigos de radicado no son válidos. Verifíquelos y vuelva a intentarlo.');
                    return;
                  }
                } else {
                  alert('Los códigos de radicado no son válidos. Verifíquelos y vuelva a intentarlo.');
                  return;
                }
              }
            } else {
              alert('Los códigos de radicado no son válidos. Verifíquelos y vuelva a intentarlo.');
              return;
            }
          } else {
            if (t.keys == ''){
              alert('Escriba las palabras claves para realizar la búsqueda');
              return;
            } else {
              /*
              p.dcm_entidad = t.keys;
              p.dcm_asunto = t.keys;
              p['indices.ixd_valor'] = t.keys;
              */
              p['$text'] = t.keys;
            }
          }
        }
        // Fin -- Buscar múltiples radicados
        this.debug = p;

        this.disableForm(true);

        p.cmp_id = String(this.$store.state.company);
        p.usu_id = String(this.$store.state.user);
        if (p.today){
          //this.numDias = 0;
          //this.limitItems = 0;
          //var now = new Date();
          //p.dcm_ano = now.getFullYear(now); //Limitar a el año actual
        }

        //p.limit = 10;
        //p.skip = 0;

        /*
        if (p.skip == null){
          this.skip = 0;
        }
        */
       
        var time = new Date();
        this.searchLastTime = time.getTime();

        if (!expand){
          this.changeType();
        }
        if (!update){
          if (this.showArchivo){
            p.skip = 0;
            p.limit = 200;
          } else {
            p.skip = this.skipItems;
            p.limit = this.limitItems;
          }
        } else {
          p.skip = 0;
          p.limit = 100;
        }

        this.modalMsg = 'Por favor espere mientras se consultan los datos..';
        this.showProgress = true;

        this.message = 'Buscando documentos';
        let uri = '/docs/search';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        //this.items = [];

        await this.axios.post(uri, p)
        .then(async (response) => {
          if (response.data.length){
            var list = [];
            if (!update){
              this.search = p;

              this.skipItems += this.rangeItems;
              this.limitItems = this.rangeItems;
            }

            var time = new Date();
            this.searchLastTime = time.getTime();

            list = response.data;

            this.archiveList = false;
            if (this.showArchivo){
              const tpocor = this.tpocors.find(i => i._id === list[0].tcr_id);
              if (tpocor){
                if (!tpocor.tcr_corr){
                  this.archiveList = true;
                }
              }
            }

            /*
            let docs = response.data;
            let list = [];

            for (var i = 0; i < docs.length; i++){
              let add_doc = false;

              let lastSeg = docs[i].dcm_fcharad;
              for (var j = 0; j < docs[i].seguimientos.length; j++){
                if (docs[i].seguimientos[j].Fecha > lastSeg){
                  lastSeg = docs[i].seguimientos[j].Fecha;
                }
                if (docs[i].seguimientos[j].ParaUsuarioId == this.$store.state.user){
                  if (docs[i].seguimientos[j].Nuevo){
                    docs[i].notificar = true;
                    //j = docs[i].seguimientos.length;
                  } else {
                    docs[i].notificar = false;
                  }

                  add_doc = true;
                }
              }

              docs[i].Fecha = lastSeg;

              if (!add_doc){
                for (var j = 0; j < this.uxrs.length; j++){
                  if ((docs[i].dep_id == this.uxrs[j].dep_id && docs[i].ser_id == this.uxrs[j].ser_id && docs[i].tcr_id == this.uxrs[j].tcr_id)){
                    add_doc = true;
                    j = this.uxrs.length;
                  }
                }
              }

              if (add_doc){
                list.push(docs[i]);
              }
            }

            /*
            list.sort(function(a,b){
              if (a.Fecha > b.Fecha) { return -1; };
              if (a.Fecha < b.Fecha) { return 1; };
              return 0;
            });
            */
            /*
            list = list;
            */

            this.delItems = [];
            this.allChecked = false;
            if (p.today || p.Fechaini){
              //this.keywords = '';
              this.tcr_id = String(p.tcr_id);
              this.filter = false;
            } else {
              this.tcr_id = '';
              this.filter = true;
            }

            // Revisar si existen respuestas y traer dias no laborales dnl
            await this.revisarRespuestas(list, async (dnl) => {
              this.dnl = dnl;

              for (var i = 0; i < list.length; i++){
                const seguimiento = list[i].seguimientos?.find(m => m.ParaUsuarioId === this.$store.state.user && m.Nuevo);
                if (seguimiento) {
                  list[i].notificar = true;
                } else {
                  list[i].notificar = false;
                }

                const tpocor = this.tpocors.find(m => m._id === list[i].tcr_id);
                list[i].tcr_nmbre = tpocor?.tcr_nmbre;

                const tpotabla = this.tpotablas.find(m => m._id === list[i].tta_id);
                list[i].tta_nmbre = tpotabla?.tta_nmbre;

                const dependencia = this.dependencias.find(m => m._id === list[i].dep_id);
                list[i].dep_nmbre = dependencia?.dep_nmbre;

                const serie = this.series.find(m => m._id === list[i].ser_id);
                list[i].ser_nmbre = serie?.ser_nmbre;
                if (list[i].dcm_tres == undefined){
                  list[i].dcm_tres = serie?.ser_tres;
                }

                if (list[i].ent_id != undefined){
                    list[i].dcm_entidad = await this.fetchEntidad(list[i], String(list[i].ent_id));                
                }

                const sala = this.salas.find(m => m._id === list[i].sal_id);
                list[i].sal_nmbre = sala?.sal_nmbre;

                const tpoarch = this.tpoarchs.find(m => m._id === list[i].tpa_id);
                list[i].tpa_nmbre = tpoarch?.tpa_nmbre;

                const usuario = this.usuarios.find(m => m._id === list[i].usu_id);
                list[i].usu_nmbre = usuario?.usu_nmbre + ' ' + usuario?.usu_aplldo;

                // Calcular tiempo de respuesta
                if (list[i].dcm_rspsta){
                  /*
                  var rad = new Date(list[i].dcm_fcharad);
                  var resp = new Date(rad.getFullYear(), rad.getMonth(), rad.getDate());
                  resp.setDate(resp.getDate() + parseInt(list[i].ser_tres));
                  */
                  //var resp = this.fechaRespuesta(list[i].dcm_fcharad, parseInt(list[i].ser_tres));
                  var restante = parseInt(list[i].dcm_tres) - tiempoUtilizado(list[i].dcm_fcharad, new Date(), this.dnl);
                  list[i].restante = restante;
                  var now = new Date();
                  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

                  //if (resp.getTime() < today.getTime()){
                  if (restante < 0){
                    list[i].color = 'red';
                  //} else if (resp.getTime() === today.getTime()){
                  } else if (restante == 0){
                    list[i].color = '#FF8C00';
                  } else {
                    list[i].color = 'green';
                  }
                } else {
                  list[i].color = 'black';
                }
              }

              var topPos = document.getElementById('right_panel').scrollTop;

              if (list.length > 0){
                if (!update){
                  if (!expand){
                    this.items = list;
                  } else {
                    if (!this.showArchivo){
                      this.items.push(...list);
                    }
                  }
                } else {
                  for (var i = 0; i < list.length; i++){
                    this.items.unshift(list[list.length-1-i]);
                  }
                }
              }
                    
              this.$nextTick(function () {
                document.getElementById('right_panel').scrollTop = topPos;
                this.newData = false;
              });
            });
            
            //$state.loaded();            
          } else {
            //$state.complete();
          }

          this.disableForm(false);              
          this.showProgress = false;

        })
        .catch(err => {
          this.disableForm(false);
          this.showProgress = false;

          this.message = '¡Error al buscar los documentos' + err;
          /*
          if(err.status === 401) {
            this.$router.push({ name: '/' });
          }
          */
        });
      } else {
        this.disableForm(false);
        this.showProgress = false;
      }
    },
    expediente(i){
      if (this.items[i].show){
        this.items[i].refresh = false;
        this.items[i].show = false;
      } else {
        if (this.items[i].dcm_expediente != null){
          var pendienteRespuesta = false;

          if (this.items[i]._id == this.items[i].dcm_expediente){
            this.items[i].star = true;
            if (this.items[i].dcm_rspsta){
              pendienteRespuesta = true;
            }
          }


          this.items[i].refresh = true;
          this.$set(this.items, i, this.items[i]);
          this.message = 'Buscando documentos relacionados';
          let uri = '/docs/expediente';
          this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'index': i, 'dcm_expediente': this.items[i].dcm_expediente, '_id': this.items[i]._id })
          .then((response) => {
            let index = response.data.index
            this.items[index].expediente_docs = response.data.exp;
            this.items[index].refresh = false;
            this.items[index].show = true;

            if (!pendienteRespuesta){
              for (var k = 0; k < this.items[index].expediente_docs.length; k++){
                if (this.items[index].expediente_docs[k].dcm_rspsta){
                  pendienteRespuesta = true;
                }
              }
              if (pendienteRespuesta){
                this.items[i].color = 'blue';
              }
            }

            // Calcular tiempo de respuesta para relacionados
            for (var k = 0; k < this.items[index].expediente_docs.length; k++){
              /*
              let lastSeg = this.items[index].expediente_docs.dcm_fcharad;
              for (var m = 0; m < this.items[index].expediente_docs.seguimientos.length; m++){
                if (this.items[index].expediente_docs.seguimientos[m].Fecha > lastSeg){
                  lastSeg = this.items[index].expediente_docs.seguimientos[m].Fecha;
                }
                if (this.items[index].expediente_docs.seguimientos[m].ParaUsuarioId == this.$store.state.user){
                  if (this.items[index].expediente_docs.seguimientos[m].Nuevo){
                    this.items[index].expediente_docs.notificar = true;
                    //j = docs[i].seguimientos.length;
                  } else {
                    this.items[index].expediente_docs.notificar = false;
                  }
                }
              }

              this.items[index].expediente_docs.Fecha = lastSeg;
              */
              if (this.items[index].expediente_docs[k]._id == this.items[index].dcm_expediente){
                this.items[index].expediente_docs[k].star = true;
              }

              const dependencia = this.dependencias.find(i => i._id === this.items[index].expediente_docs[k].dep_id);
              this.items[index].expediente_docs[k].dep_nmbre = dependencia?.dep_nmbre;
              
              var serie = this.series.find(i => i._id === this.items[index].expediente_docs[k].ser_id);
              this.items[index].expediente_docs[k].ser_nmbre = serie?.ser_nmbre;

              /*
              if (this.items[index].expediente_docs[k].ent_id != undefined){
                  this.items[index].expediente_docs[k].dcm_entidad = this.fetchEntidad(this.items[index].expediente_docs[k], String(this.items[index].expediente_docs[k].ent_id));                
              }
              */

              const sala = this.salas.find(i => i._id === this.items[index].expediente_docs[k].sal_id);
              this.items[index].expediente_docs[k].sal_nmbre = sala?.sal_nmbre;

              const tpoarch = this.tpoarchs.find(i => i._id === this.items[index].expediente_docs[k].tpa_id);
              this.items[index].expediente_docs[k].tpa_nmbre = tpoarch?.tpa_nmbre;
              
              const usuario = this.usuarios.find(i => i._id === this.items[index].expediente_docs[k].usu_id);
              this.items[index].expediente_docs[k].usu_nmbre = usuario?.usu_nmbre + ' ' + usuario?.usu_aplldo;

              if (this.items[index].expediente_docs[k].dcm_rspsta){
                /*
                var rad = new Date(this.items[index].expediente_docs[k].dcm_fcharad);
                var resp = new Date(rad.getFullYear(), rad.getMonth(), rad.getDate());
                resp.setDate(resp.getDate() + parseInt(this.items[index].ser_tres));
                */
                //var resp = this.fechaRespuesta(this.items[index].expediente_docs[k].dcm_fcharad, parseInt(this.items[index].ser_tres));

                if (this.items[index].expediente_docs[k].dcm_tres == undefined){
                  var serie = this.series.find(i => i._id === this.items[index].ser_id);
                  this.items[index].expediente_docs[k].dcm_tres = serie?.ser_tres;  
                }

                var restante = parseInt(this.items[index].expediente_docs[k].dcm_tres) - tiempoUtilizado(this.items[index].expediente_docs[k].dcm_fcharad, new Date(), this.dnl);
                this.items[index].expediente_docs[k].restante = restante;
                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

                //if (resp.getTime() < today.getTime()){
                if (restante < 0){
                  this.items[index].expediente_docs[k].color = 'red';
                //} else if (resp.getTime() === today.getTime()){
                } else if (restante == 0){
                  this.items[index].expediente_docs[k].color = '#FF8C00';
                } else {
                  this.items[index].expediente_docs[k].color = 'green';
                }

              } else {
                if (this.items[index].expediente_docs[k]._id == this.items[index].dcm_expediente){
                  this.items[index].expediente_docs[k].color = 'black';
                } else {
                  if (pendienteRespuesta){
                    this.items[index].expediente_docs[k].color = 'blue';
                  } else {
                    this.items[index].expediente_docs[k].color = 'black';
                  }
                }
              }

            }
          })
          .catch((err) => {
            this.message = '¡Error al buscar los documentos relacionados' + err;
          });
        }

      }

    },
    searchKeywords(keywords){
      this.showArchivo = false;
      this.searchItems({ keys: keywords });
    },
    advancedSearch(){
      this.showArchivo = false;
      this.searchItems(this.search);
    },
    searchItemsRelacion(index, exp, key){
      //alert(keywords);
      if (key != ''){
        var p = {};
        p.cmp_id = String(this.$store.state.company);
        p.dcm_expediente = String(exp);
        p._id = String(key);
        this.message = 'Buscando documentos';
        let uri = '/docs/searchrelation';
        this.axios.post(uri, p)
        .then(response => {
          console.log(response);

          this.items[index].expediente_docs = response.data;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    fechaRespuesta(inicio, dias){ // Fecha de respuesta contando solo los días laborales (lunes a viernes)
      var t = new Date(inicio);
      var fecha = new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0);
      var n = 0;

      while (n < dias) {
        t.setDate(t.getDate() + 1);
        if (t.getDay() != 0 && t.getDay() != 6){ // Si es diferente a domingo y sábado
          fecha = t;
          n++;
        }
      }
      return fecha;
    },
    classObject(id){
      return { checked: this.itemChecked(id) && this.selectedRow != id, selected: this.selectedRow == id }
    },
    styleArchiveAddButton(modify) {
      let events = "auto";
      let color = "#1976d2";
      if (!modify) {
        events = "none";
        color = "gray";
      }
      return {
        "pointer-events": events,
        "color": color
      }
    },
    newSearch () {
      this.search = { today: undefined };
    },
    createItem(){
      this.$router.replace({ name: 'CreateDoc', params: {add: true} });
    },
    editItem(id){
      this.$router.replace({ name: 'CreateDoc', params: {id: id, add: false} });
    },
    showMenuContext(e, data){
      var allowRelation = false;
      var allowResponse = false;
      var allowGrouping = false;
      var allowUngrouping = false;
      var allowArchiving = false;
      var allowUnarchiving = false;
      var allowLending = false;

      if (this.$store.state.prestamos){
        allowLending = true;
      }

      if (this.delItems.length > 1 && this.itemChecked(data.item._id)){
        allowGrouping = true;
        allowArchiving = true;

        if (allowGrouping){
          this.$refs.menu.open(e, { 'allowGrouping': allowGrouping, 'allowArchiving': allowArchiving, 'allowLending': allowLending });
        }
      } else if (this.delItems.length == 0 || (this.delItems.length == 1 && this.itemChecked(data.item._id))) {
        this.selectedRow = data.item._id;

        let uri = '/uxr/response';
        this.axios.post(uri, { 'cmp_id': String(this.$store.state.company), 'usu_id': String(this.$store.state.user), 'dep_id': data.item.dep_id, 'ser_id': data.item.ser_id })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++){
            //alert(data.item.tcr_id + '/' + response.data[i].tcr_id);
            //if (data.item.tcr_id == response.data[i].tcr_id){
              if (response.data[i].uxr_mdfcr){
                allowRelation = true;
                //i = response.data.length;
              }

            //}
            if (data.item.tcr_id == response.data[i].tcr_id){
              if (response.data[i].uxr_archvr){
                allowArchiving = true;
                if (data.item.dcm_archivado){
                  allowUnarchiving = true;
                }
                if (data.subitem) {
                  allowUngrouping = true;
                }
              }
            }
          }

          var mostrarOpciones = () => {
            if (allowRelation||allowResponse||allowUngrouping||allowArchiving||allowUnarchiving||allowLending){
              this.$refs.menu.open(e, { 'item': data.item, 'allowRelation': allowRelation, 'allowResponse': allowResponse, 'allowUngrouping': allowUngrouping, 'allowArchiving': allowArchiving, 'allowUnarchiving': allowUnarchiving, 'allowLending': allowLending });
            }
          };        

          this.usuarioSegResponde = false;

          if (data.item.dcm_rspsta){
            allowArchiving = false;
            allowUnarchiving = false;
            var tcr_respuesta = '';
            for (var i = 0; i < this.tpocors.length; i++){
              if (data.item.tcr_id == this.tpocors[i]._id){
                tcr_respuesta = this.tpocors[i].tcr_respuesta;
                i = this.tpocors.length;
              }
            }

            if (tcr_respuesta != ''){
              for (var i = 0; i < response.data.length; i++){
                if (tcr_respuesta == response.data[i].tcr_id){
                  if (response.data[i].uxr_mdfcr){
                    allowResponse = true;
                    i = response.data.length;
                  }
                }
              }

              // EL USUARIO DE SEGUIMIENTO DEBE RESPONDER ?
              if (!allowResponse){
                let uri = '/docs/estado-responder';
                this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
                this.axios.post(uri, { id: data.item._id, cmp_id: this.$store.state.company, usu_id: this.$store.state.user })
                .then((resp) => {
                  if (resp.data.existe){
                    allowResponse = true;
                    allowRelation = true;
                    this.usuarioSegResponde = true;
                    mostrarOpciones();
                  }
                })
                .catch((err) => {
                  mostrarOpciones();
                });
              } else {
                mostrarOpciones();
              }
            } else {
              mostrarOpciones();
            }
          } else {
            mostrarOpciones();
          }          
        })
        .catch((err) => {

        });
      }
    },
    selectRow(e, id){
      //alert('Seleccionado: ' + index);
      this.selectedRow = id;
    },
    unselectRow(){
      this.selectedRow = '';
    },
    onClick(text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      console.log(data);

      var expediente;

      //alert(data.item.dcm_expediente);

      if (data.item.dcm_expediente != undefined){
        expediente = String(data.item.dcm_expediente);
      } else {
        expediente = String(data.item._id);
      }

      //alert(expediente);

      var tcr_respuesta = '';
      var tcr_cdgo = '';
      for (var i = 0; i < this.tpocors.length; i++){
        if (data.item.tcr_id == this.tpocors[i]._id){
          tcr_respuesta = String(this.tpocors[i].tcr_respuesta);
          tcr_cdgo = this.tpocors[i].tcr_cdgo;
          i = this.tpocors.length;
        }
      }

      var tdo_id = null;
      if (tcr_cdgo == 'O'){
        for (var k = 0; k < this.tpodocs.length; k++){
          if (this.tpodocs[k].tdo_cdgo == 1){
            tdo_id = this.tpodocs[k]._id;
            j = this.tpodocs.length;
          }
        }
      }

      if (text == "respuesta"){
        //alert(this.tpoarchs.length);
        var tpa_id = '';
        for (var j = 0; j < this.tpoarchs.length; j++){
          if (this.tpoarchs[j].tpa_cdgo == 1){
            tpa_id = String(this.tpoarchs[j]._id);
            j = this.tpoarchs.length;
          }
        }

        if (tcr_respuesta != ''){
          for (var i = 0; i < this.tpocors.length; i++){
            if (String(tcr_respuesta) == String(this.tpocors[i]._id)){
              if (this.tpocors[i].tcr_rad_hini && this.tpocors[i].tcr_rad_hfin){
                var tcr_nmbre = this.tpocors[i].tcr_nmbre;
                var hi = new Date(this.tpocors[i].tcr_rad_hini);
                var hf = new Date(this.tpocors[i].tcr_rad_hfin);

                let uri = '/api/now';
                this.axios.post(uri).then((response) => {
                  var now = new Date(response.data.now);
                  var di = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hi.getHours(), hi.getMinutes(), 0);
                  var df = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hf.getHours(), hf.getMinutes(), 0);

                  if (now.getDay() == 6 || now.getDay() == 0 || di > now || now > df){
                    let h = moment(response.data.now).format('dddd, DD [de] MMMM [de] YYYY, hh:mm A');
                    alert('La fecha y hora actual (' + h + ') está fuera del horario permitido para radicar ' + tcr_nmbre);
                  } else {
                    this.$router.replace({ name: 'CreateDoc', params: { add: true, search: this.search, respuesta: true, dcm_expediente: expediente, dcm_rlcion: String(data.item._id), tcr_respuesta: tcr_respuesta, tpa_id: tpa_id, tcr_id: String(data.item.tcr_id), tcr_cdgo: tcr_cdgo, dcm_ano: data.item.dcm_ano, dcm_cdgo: data.item.dcm_cdgo, tta_id: String(data.item.tta_id), tav_nmro: data.item.tav_nmro, dep_id: String(data.item.dep_id), ser_id: String(data.item.ser_id), dcm_entidad: data.item.dcm_entidad, ent_id: data.item.ent_id, dcm_ciudad: data.item.dcm_ciudad, dcm_direccion: data.item.dcm_direccion, dcm_email: data.item.dcm_email, dcm_asunto: data.item.dcm_asunto, dcm_web: data.item.dcm_web, dcm_resp_email: data.item.dcm_resp_email, usuarioSegResponde: this.usuarioSegResponde } });
                  }
                });
              } else {
                this.$router.replace({ name: 'CreateDoc', params: { add: true, search: this.search, respuesta: true, dcm_expediente: expediente, dcm_rlcion: String(data.item._id), tcr_respuesta: tcr_respuesta, tpa_id: tpa_id, tcr_id: String(data.item.tcr_id), tcr_cdgo: tcr_cdgo, dcm_ano: data.item.dcm_ano, dcm_cdgo: data.item.dcm_cdgo, tta_id: String(data.item.tta_id), tav_nmro: data.item.tav_nmro, dep_id: String(data.item.dep_id), ser_id: String(data.item.ser_id), dcm_entidad: data.item.dcm_entidad, ent_id: data.item.ent_id, dcm_ciudad: data.item.dcm_ciudad, dcm_direccion: data.item.dcm_direccion, dcm_email: data.item.dcm_email, dcm_asunto: data.item.dcm_asunto, dcm_web: data.item.dcm_web, dcm_resp_email: data.item.dcm_resp_email, usuarioSegResponde: this.usuarioSegResponde } });
              }
            }
          }          
        } else {
          alert('No se ha definido el tipo de respuesta para esta clase de documento.')
        }
      } else if (text == "relacion"){
        this.$router.replace({ name: 'CreateDoc', params: { add: true, search: this.search, respuesta: false, dcm_expediente: expediente, dcm_rlcion: String(data.item._id), tdo_id: tdo_id, tcr_id: String(data.item.tcr_id), tcr_cdgo: tcr_cdgo, dcm_ano: data.item.dcm_ano, dcm_cdgo: data.item.dcm_cdgo, tta_id: String(data.item.tta_id), tav_nmro: data.item.tav_nmro, dep_id: String(data.item.dep_id), ser_id: String(data.item.ser_id), dcm_entidad: data.item.dcm_entidad, ent_id: String(data.item.ent_id), dcm_ciudad: data.item.dcm_ciudad, dcm_direccion: data.item.dcm_direccion, dcm_email: data.item.dcm_email, dcm_asunto: data.item.dcm_asunto } });
      }
    },
    onClickAgrupar (text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      console.log(data);

      var expediente;
      var otroExp = []; //Existe otro expediente?
      var dep_ser = {};
      var t;
      var expt; //Expediente temporal

      for (var i = 0; i < this.delItems.length; i++){
        for (var j = 0; j < this.items.length; j++){
          if (this.delItems[i] == this.items[j]._id){
            t = this.items[j];
            j = this.items.length;
          }
        }

        if (t.dcm_expediente != undefined && t.dcm_expediente != null && t.dcm_expediente != ''){
          expt = t.dcm_expediente;
          otroExp.push(expt);
        } else {
          expt = t._id;
        }

        if (i == 0) {
          expediente = expt;
          dep_ser.dep_id = t.dep_id;
          dep_ser.ser_id = t.ser_id;
        } else {
          if (expt < expediente){
            expediente = expt;
          }
          if (dep_ser.dep_id != t.dep_id || dep_ser.ser_id != t.ser_id){
            alert('Los documentos no son de la misma dependencia y serie y no se pueden relacionar. Revise los documentos seleccionados y vuelva a intentarlo.');
            return;
          }
        }
      }

      var resp = true;
      for (var i = 0; i < otroExp.length; i++){
        if (expediente != otroExp[i]){
          resp = confirm('Algunos de los documentos ya pertenecen a otro expediente. Desea agrupar todos los documentos relacionados bajo un mismo expediente?');
          if (!resp){
            return;
          }
          i = otroExp.length;
        }
      }

      if (resp){
        for (var i = 0; i < this.delItems.length; i++){
          // Poner expediente
          let uri = '/docs/relacionar';
          this.axios.post(uri, { 'item': this.delItems[i], 'expediente': expediente })
          .then((response) => {

          })
          .catch((err) => {
            this.message = 'Error al actualizar el expediente.' + err;
            alert(this.message);
          });
        }

        this.searchItems(this.search);
      }

    },
    onClickDesagrupar (text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      //console.log(data);

      var resp = confirm('Desea quitar este documento del expediente?');
      if (resp){
        // Quitar del expediente
        let uri = '/docs/desrelacionar';
        this.axios.post(uri, { 'id': data.item._id })
        .then((response) => {
          this.searchItems(this.search);
        })
        .catch((err) => {
          this.message = 'Error al quitar del expediente.' + err;
          alert(this.message);
        });
      }

    },
    onClickArchivar (text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      //console.log(data);

      var expediente;
      var otroExp = []; //Existe otro expediente?
      var dep_ser = {};
      var t;
      var expt; //Expediente temporal
      var can_archive; //Puede cambiar el documento de la sala donde está archivado

      if (this.delItems.length == 0){
        this.delItems.push(data.item._id);
      }

      for (let i = 0; i < this.delItems.length; i++){
        for (let j = 0; j < this.items.length; j++){
          if (this.delItems[i] == this.items[j]._id){
            t = this.items[j];
            j = this.items.length;
          }
        }

        if (t.dcm_rspsta){
          alert('Alguno(s) de los documentos está(n) pendiente(s) de respuesta y no se pueden archivar todavía. Revise los documentos seleccionados y vuelva a intentarlo.');
          return;
        }

        if (t.dcm_archivado){
          can_archive = false;
          for (let j = 0; j < this.salas.length; j++){
            if (t.sal_id == this.salas[j]._id){
              can_archive = true;
              j = this.salas.length;
            }
          }

          if (!can_archive){
            alert('Alguno(s) de los documentos está(n) ya archivado(s) y no tiene permisos para cambiarlo(s) de sala. Verifique la sala donde están archivados y solicite el cambio al administrador o un usuario con esos permisos.');
            return;
          }
        }

        if (t.dcm_expediente != undefined && t.dcm_expediente != null && t.dcm_expediente != ''){
          expt = t.dcm_expediente;
          otroExp.push(expt);
        } else {
          expt = t._id;
        }

        if (i == 0) {
          expediente = expt;
          dep_ser.dep_id = t.dep_id;
          dep_ser.ser_id = t.ser_id;
        } else {
          if (expt < expediente){
            expediente = expt;
          }
          if (dep_ser.dep_id != t.dep_id || dep_ser.ser_id != t.ser_id){
            alert('Los documentos no son de la misma dependencia y serie y no se pueden archivar juntos. Revise los documentos seleccionados y vuelva a intentarlo.');
            return;
          }
        }
      }

      var resp = true;
      for (let i = 0; i < otroExp.length; i++){
        if (expediente != otroExp[i]){
          resp = confirm('Algunos de los documentos ya pertenecen a otro expediente. Desea archivar todos los documentos relacionados bajo un mismo inventario?');
          if (!resp){
            return;
          }
          i = otroExp.length;
        }
      }

      if (resp){
        var tpa_id = '';
        for (let j = 0; j < this.tpoarchs.length; j++){
          if (this.tpoarchs[j].tpa_cdgo == 1){
            tpa_id = String(this.tpoarchs[j]._id);
            j = this.tpoarchs.length;
          }
        }

        this.ubicacion = { 'tpa_id': tpa_id };
        $('#archivarModal').modal('show');

      }

    },
    onClickDesarchivar(text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      //console.log(data);

      var can_archive; //Puede cambiar el documento de la sala donde está archivado

      if (data.item.dcm_archivado){
        can_archive = false;
        for (let j = 0; j < this.salas.length; j++){
          if (data.item.sal_id == this.salas[j]._id){
            can_archive = true;
            j = this.salas.length;
          }
        }

        if (!can_archive){
          alert('Alguno(s) de los documentos está(n) ya archivado(s) y no tiene permisos para cambiarlo(s) de sala. Verifique la sala donde están archivados y solicite el cambio al administrador o un usuario con esos permisos.');
          return;
        }
      }

      var resp = confirm('Desea desarchivar este documento y todos sus documentos relacionados?');
      if (resp){
        // Quitar del archivo
        let uri = '/docs/desarchivar';
        this.axios.post(uri, { 'id': data.item._id })
        .then((response) => {
          this.searchItems(this.search);
        })
        .catch((err) => {
          this.message = 'Error al desarchivar el expediente.' + err;
          alert(this.message);
        });
      }

    },
    onClickPrestar(text, data) {
      //alert('You clicked ${text}! on item: ' + data.item._id);
      //console.log(data);

      if (this.delItems.length == 0){
        this.delItems.push(data.item._id);
      }

      $('#prestarModal').modal('show');

    },
    clickArchivo(){
      this.showDcmnts = false;
      this.showArchivo = true;
      this.items = [];
      this.treeData = [{ id: 0, name: 'Archivo', type: 'arc', children: [] }];
      for (var i = 0; i < this.salas.length; i++){
        let id_sala = this.salas[i]._id;
        this.treeData[0].children.push({ id: id_sala, name: this.salas[i].sal_nmbre, type: 'sal', children: [] });
      }
      var container = document.getElementById('container');
      var element = document.getElementById('left_panel');
      var right_panel = document.getElementById('right_panel');
      //var menu = document.getElementById('left_menu');
      var resizer = document.getElementById('resizer');

      this.resizeArchivo(container, element, right_panel, resizer);
      //resizer.style.left = '0px';
      //resizer.style.top = '0px';
      //resizer.style.width = '8px';
      //resizer.style.left = (right_panel.offsetLeft) + 'px';

      //right_panel.style.width = Math.trunc(container.clientWidth * 0.7);
      // element.style.width = (container.clientWidth - right_panel.clientWidth - 2 * right_panel.clientLeft - 2 * resizer.clientLeft - 1) + 'px';

    },
    showQueries(t, id){
      if (t == 'B'){
        if (this.keywords == ''){
          alert('Escriba las palabras claves para realizar la búsqueda');
          return;
        }
      }
      this.showArchivo = false;
      this.showEstantes = false;
      this.showCajas = false;
      this.showLegajos = false;
      this.showDcmnts = true;

      var container = document.getElementById('container');
      var element = document.getElementById('left_panel');
      var right_panel = document.getElementById('right_panel');
      //var menu = document.getElementById('left_menu');
      var resizer = document.getElementById('resizer');

      container.style.overflow = 'hidden';          
      element.style.width = '0px';
      right_panel.style.width = (container.clientWidth) + 'px';
      //right_panel.style.left = '0px';
      resizer.style.width = '0px';
      resizer.style.left = (right_panel.offsetLeft) + 'px';
      //resizer.style.top = '0px';
      this.newData = false;

      switch(t) {
        case 'T':
          this.searchItems({ tcr_id: id, today: true });
          break;
        case 'P':
          this.searchItems({ dcm_rspsta: true });
          break;
        case 'S':
          this.searchItems({ sinleer: true });
          break;
        case 'B':
          this.searchItems({ keys: this.keywords });
          break;
        case 'A':
          this.searchItems(this.search);
          break;
        case 'C':
          this.filter = false;
          this.keywords = '';
          this.searchItems({ today: true });
          break;
      }
    },
    openNode(){
      this.treeData = this.$store.state.treeData;
      this.open = this.$store.state.open;
      this.active = this.$store.state.active;
    },
    nodeSelect(node, isSelected) {
      console.log('Node ' + node.data.name + ' has been ' + (isSelected ? 'selected' : 'deselected'))
      if (isSelected) {
        this.selectedNode = node.data
        if (node.data.id == 0){
          this.showDcmnts = false;
          this.showEstantes = false;
          return;
        }
        for (var i = 0; i < this.salas.length; i++){
          if (node.data.id == this.salas[i]._id){
            this.showDcmnts = false;
            this.showEstantes = true;
            this.allowModify = this.salas[i].uxs_mdfcr;
            this.getEstantes(node.data.id);
            return;
          }
        }
        for (var i = 0; i < this.estantes.length; i++){
          if (node.data.id == this.estantes[i]._id){
            this.showDcmnts = false;
            this.showCajas = true;
            this.allowModify = this.salas[i].uxs_mdfcr;
            this.getCajas(node.data.id);
            return;
          }
        }
      } else if (node.data === this.selectedNode) {
          this.selectedNode = null
      }
    },
    menuItemSelected(item, node) {
        if (item.code == 'ADD_CHILD_NODE') {
            node.appendChild({
                id: this.count++,
                name: 'My new node',
                children: []
            });
        }
    },
    newEstante() {
      this.estantesButtonText = 'Agregar';
      this.estante = { cmp_id: this.$store.state.company, sal_id: this.active[0] };
      this.addEst = true;
    },
    addEstante(i) {
      //alert(estante);
      if (i.sal_id == null){
        alert('Seleccione la Sala');
        return;
      }
      if (i.est_cdgo == null){
        alert('Escriba el número del estante');
        return;
      }
      if (i.est_bndjas == null){
        alert('Escriba el número de bandejas');
        return;
      }
      if (i.est_cjasxb == null){
        alert('Escriba el número de cajas por bandeja');
        return;
      }

      /*
      for (var j = 0; j < this.salas.length; j++){
        if (String(i.sal_id) == String(this.salas[j]._id)){
          i.sal_nmbre = this.salas[j].sal_nmbre;
          j = this.salas.length;
        }
      }
      */

      if (this.addEst){
        let uri = '/estantes/add';
        //this.estante.cmp_id = String(this.$store.state.company);

        this.axios.post(uri, i)
        .then((response) => {
          console.log(response);
          //toastr.success(response.data.item, 'Response');
          this.getEstantes(i.sal_id);
          this.message = '¡Estante creado! ';

        })
        .catch(err => {
          this.message = '¡Error al crear el estante! ' + err;
          alert(this.message);
        });
      } else {
        let uri = '/estantes/update/' + i._id;
        this.axios.post(uri, i)
        .then((response) => {
          this.getEstantes(i.sal_id);
          this.message = 'Estante guardado';
        })
        .catch((err) => {
          this.message = 'Error al guardar el estante';
          alert(this.message);
        });
      }
      this.estante = {};
      this.addEst = true;
    },
    editEstante(i) {
      this.estante = { _id: this.estantes[i]._id, cmp_id: this.estantes[i].cmp_id, sal_id: this.estantes[i].sal_id, est_cdgo: this.estantes[i].est_cdgo, est_cjasxb: this.estantes[i].est_cjasxb, est_bndjas: this.estantes[i].est_bndjas };
      this.addEst = false;
      this.editEst = i;
      this.estantesButtonText = 'Guardar cambios';
    },
    deleteEstantes(){
      if (this.delEstantes.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (let i = 0; i < this.delEstantes.length; i++){
            //alert('Item(' + i + '): ' + this.delEstantes[i]);
            let uri = '/estantes/delete/' + this.delEstantes[i];
            this.axios.get(uri)
            .then(res => {

            })
            .catch(err => {
              console.log(err);
              for (let m = 0; m < this.estantes.length; m++){
                if (this.delEstantes[i] == this.estantes[m]._id){
                  alert('No se ha podido eliminar el item ' + this.estantes[m]);
                  m = this.estantes.length;
                }
              }
            });
          }

          this.getEstantes(this.active[0]);
        }
      }
      return;
    },
    checkAllEstantes(){
      this.delEstantes = [];
      if (!this.allEstantesChecked){
        for (var i = 0; i < this.estantes.length; i++){
          this.delEstantes.push(this.estantes[i]._id);
        }
      }
    },
    newCaja() {
      this.cajasButtonText = 'Agregar caja';
      var node = this.searchTreeById(this.treeData[0], this.active[0]);
      if (node){
        var sala = this.searchTreeParentById(this.treeData[0], this.active[0]);
        let uri = '/estantes/sala';
        this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: sala.id })
        .then((response) => {
          this.estantes_caj = response.data;
        });
        this.caja = { 'cmp_id': this.$store.state.company, 'sal_id': sala.id, 'sal_nmbre': sala.name, 'est_id': this.active[0], 'est_nmbre': node.name };
      }
      this.addCaj = true;
    },
    addCaja(i) {
      //alert(caja);
      if (i.est_id == null){
        alert('Seleccione el estante');
        return;
      }
      if (i.caj_cdgo == null){
        alert('Escriba el número de la caja');
        return;
      }
      if (i.caj_bndja == null){
        alert('Escriba el número de la bandeja');
        return;
      }

      if (this.addCaj){
        let uri = '/cajas/add';
        //this.caja.cmp_id = String(this.$store.state.company);

        this.axios.post(uri, i)
        .then((response) => {
          console.log(response);
          //toastr.success(response.data.item, 'Response');
          this.getCajas(i.est_id);
          this.message = 'Caja creada! ';

        })
        .catch(err => {
          this.message = '¡Error al crear la caja! ' + err;
          alert(this.message);
        });
      } else {
        let uri = '/cajas/update/' + i._id;
        this.axios.post(uri, i)
        .then((response) => {
          this.getCajas(i.est_id);
          this.message = 'Caja guardada';
        })
        .catch((err) => {
          this.message = 'Error al guardar la caja';
          alert(this.message);
        });
      }
      this.caja = {};
      this.addCaj = true;
    },
    editCaja(i) {
      let uri = '/estantes/sala';
      this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: String(this.cajas[i].sal_id) })
      .then((response) => {
        this.estantes_caj = response.data;
      });

      this.caja = { _id: this.cajas[i]._id, cmp_id: this.cajas[i].cmp_id, sal_id: this.cajas[i].sal_id, tpa_id: this.cajas[i].tpa_id, caj_cdgo: this.cajas[i].caj_cdgo, caj_nmbre: this.cajas[i].caj_nmbre, est_id: this.cajas[i].est_id, caj_bndja: this.cajas[i].caj_bndja } ;
      var node = this.searchTreeById(this.treeData[0], this.active[0]);
      if (node){
        var sala = this.searchTreeParentById(this.treeData[0], this.active[0]);
        this.caja.sal_nmbre = sala.name;
        this.caja.est_nmbre = node.name;
      }

      this.addCaj = false;
      this.editCaj = i;
      this.cajasButtonText = 'Guardar cambios';
    },
    deleteCajas(){
      if (this.delCajas.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (let i = 0; i < this.delCajas.length; i++){
            //alert('Item(' + i + '): ' + this.delEstantes[i]);
            let uri = '/cajas/delete/' + this.delCajas[i];
            this.axios.get(uri)
            .then(res => {

            })
            .catch(err => {
              console.log(err);
              for (let m = 0; m < this.cajas.length; m++){
                if (this.delCajas[i] == this.cajas[m]._id){
                  alert('No se ha podido eliminar el item ' + this.cajas[m]);
                  m = this.cajas.length;
                }
              }
            });
          }

          this.getCajas(this.active[0]);
        }
      }
      return;
    },
    checkAllCajas(){
      this.delCajas = [];
      if (!this.allCajasChecked){
        for (var i = 0; i < this.cajas.length; i++){
          this.delCajas.push(this.cajas[i]._id);
        }
      }
    },
    newLegajo() {
      this.legajosButtonText = 'Agregar inventario';
      var node = this.searchTreeById(this.treeData[0], this.active[0])
      if (node){
        this.legajo = { cmp_id: this.$store.state.company, caj_id: this.active[0], name: node.name };
      }
      this.addLgj = true;
    },
    addLegajo(i) {
      //alert(legajo);
      if (i.caj_id == null){
        alert('Seleccione la caja');
        return;
      }
      if (i.lgj_nmro == null){
        alert('Escriba el número del inventario');
        return;
      }
      if (i.uco_id == null){
        alert('Seleccione la unidad de conservación');
        return;
      }

      if (this.addLgj){
        let uri = '/legajos/add';
        //this.legajos.cmp_id = String(this.$store.state.company);

        this.axios.post(uri, i)
        .then((response) => {
          console.log(response);
          //toastr.success(response.data.item, 'Response');
          this.getLegajos(i.caj_id);
          this.message = 'Inventario creado! ';

        })
        .catch(err => {
          this.message = '¡Error al crear el inventario! ' + err;
          alert(this.message);
        });
      } else {
        let uri = '/legajos/update/' + i._id;
        this.axios.post(uri, i)
        .then((response) => {
          this.getLegajos(i.caj_id);
          this.message = 'Inventario guardado';
        })
        .catch((err) => {
          this.message = 'Error al guardar el inventario';
          alert(this.message);
        });
      }
      this.legajo = {};
      this.addLgj = true;
    },
    editLegajo(i) {
      this.legajo = { _id: this.legajos[i]._id, cmp_id: this.legajos[i].cmp_id, caj_id: this.legajos[i].caj_id, lgj_nmro: this.legajos[i].lgj_nmro, lgj_nmbre: this.legajos[i].lgj_nmbre, uco_id: this.legajos[i].uco_id };
      var node = this.searchTreeById(this.treeData[0], this.active[0])
      if (node){
        this.legajo.name = node.name;
      }
      this.addLgj = false;
      this.editLgj = i;
      this.legajosButtonText = 'Guardar cambios';
    },
    deleteLegajos3(){
      if (this.delLegajos.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (let i = 0; i < this.delLegajos.length; i++){
            let id = this.delLegajos[i];
            let uri = '/legajos/ubicacion/' + id;
            this.axios.get(uri).then((response) => {
              let legajo = response.data;
              let uri = '/docs/legajo/cantidad';
              this.axios.post(uri, legajo).then((response) => {
                if (response.data.dcmnts.length == 0){
                  let uri = '/legajos/delete/' + response.data.id;
                  this.axios.get(uri)
                  .then(res => {

                  })
                  .catch(err => {
                    alert('No se ha podido eliminar el legajo ' + legajo.lgj_nmro + '. ' + err);
                  });
                } else {
                  alert('El inventario ' + legajo.lgj_nmro + ' no se puede eliminar porque contiene documentos. Desarchívelos o elimínelos y vuelva a intentarlo.')
                  return;
                }

              })

            }).catch((err) => {
              alert('Uno de los legajos relacionados ya no existe. Actualice la lista y vuelva a intentarlo.')
              return;
            });

          }

          this.getLegajos(this.active[0]);
        }
      }
      return;
    },    
    deleteLegajosCallback(delLegajos, callback){
      for (let i = 0; i < delLegajos.length; i++){
        let id = delLegajos[i];
        let uri = '/legajos/ubicacion/' + id;
        this.axios.get(uri).then((response) => {
          let legajo = response.data;
          let uri = '/docs/legajo/cantidad';
          this.axios.post(uri, legajo).then((response) => {
            if (response.data.dcmnts.length == 0){
              let uri = '/legajos/delete/' + response.data.id;
              this.axios.get(uri)
              .then(res => {

              })
              .catch(err => {
                alert('No se ha podido eliminar el legajo ' + legajo.lgj_nmro + '. ' + err);
              });
            } else {
              alert('El inventario ' + legajo.lgj_nmro + ' no se puede eliminar porque contiene documentos. Desarchívelos o elimínelos y vuelva a intentarlo.')
              return;
            }

          })

        }).catch((err) => {
          alert('Uno de los legajos relacionados ya no existe. Actualice la lista y vuelva a intentarlo.')
          return;
        });

      }

      var node = this.searchTreeById(this.treeData[0], this.active[0]);
      if (node){
        this.getLegajos(this.active[0]);
        alert('Actualizando legajos');
      } else {
        alert('No hay caja seleccionada');
      }

      callback();
    },
    deleteLegajos2(){
      if (this.delLegajos.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          //deleteLegajosCallback(this.delLegajos, this.getLegajos(this.active[0]));
          this.deleteLegajosCallback(this.delLegajos, function() {
            //alert('Función terminada.')
          });
        }
      }
      return;
    },
    deleteLegajos(){
      if (this.delLegajos.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (let i = 0; i < this.delLegajos.length; i++){
            //alert('Item(' + i + '): ' + this.delEstantes[i]);

            /*
            let uri = '/docs/legajo/cantidad';
            this.axios.post(uri, {  })
            */

            let uri = '/legajos/delete/' + this.delLegajos[i];
            this.axios.get(uri)
            .then(res => {

            })
            .catch(err => {
              console.log(err);
              for (let m = 0; m < this.legajos.length; m++){
                if (this.delLegajos[i] == this.legajos[m]._id){
                  alert('No se ha podido eliminar el item ' + this.legajos[m]);
                  m = this.legajos.length;
                }
              }
            });
          }

          this.getLegajos(this.active[0]);
        }
      }
      return;
    },
    checkAllLegajos(){
      this.delLegajos = [];
      if (!this.allLegajosChecked){
        for (var i = 0; i < this.legajos.length; i++){
          this.delLegajos.push(this.legajos[i]._id);
        }
      }
    },
    infiniteHandler($state) {
      axios.get(api, {
        params: {
          page: this.page,
        },
      }).then(({ data }) => {
        if (data.hits.length) {
          this.page += 1;
          this.list.push(...data.hits);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    changeType() {
      this.skipItems = 0;
      this.limitItems = this.rangeItems;
      this.items = [];
      this.infiniteId += 1;
    },
    searchTreeById(element, id){
      if(element.id == id){
          return element;
      } else if (element.children != null){
          var result = null;
          for (var i = 0; result == null && i < element.children.length; i++){
            result = this.searchTreeById(element.children[i], id);
          }
          return result;
      }
      return null;
    },
    searchTreeParentById(element, id){
      if (element.children != null){
        var result = null;
        for (var i = 0; result == null && i < element.children.length; i++){
          if (id == element.children[i].id){
            result = element;
          } else {
            result = this.searchTreeParentById(element.children[i], id);
          }
        }
        return result;
      }

      return null;
    },
    findParent(data, id) {
      var result = data.find(item => item.id === id);
      if (result){
        return result;
      } else {
        return this.findParent(item.children, id);
      }
    },
    pathTreeById(element, id){
      /*
      if(element.id == id){
          return element.name;
      } else if (element.children != null){
          var result = '';
          for (var i = 0; result == '' && i < element.children.length; i++){
            result = element.name + ' > ' + this.pathTreeById(element.children[i], id);
          }
          return result;
      }
      */
      return '';
    },
    exportReport(){
      var tableId = 'documentos';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        if (this.items[i].relacion){
          item.show = this.items[i].show ? "-": "+";
        } else {
          item.show = "";
        }
        item.tcr_nmbre = this.items[i].tcr_nmbre;
        item.dcm_ano = this.items[i].dcm_ano;
        item.dcm_cdgo = this.items[i].dcm_cdgo;
        item.Fecha = moment(this.items[i].Fecha).format('DD/MMM/YYYY hh:mm A');
        item.dcm_entidad = this.items[i].dcm_entidad;
        item.dcm_ciudad = this.items[i].dcm_ciudad;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dcm_tomo = this.items[i].dcm_tomo;
        item.dcm_fcharad = moment(this.items[i].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
        item.dcm_tres = this.items[i].dcm_tres;
        item.restante = this.items[i].restante;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_anexos = this.items[i].dcm_anexos;
        item.usu_nmbre = this.items[i].usu_nmbre;
        if (this.items[i].dcm_archivado){
          item.archivo = "Sala: " + this.items[i].sal_nmbre + " // " + this.items[i].tpa_nmbre + " // Caja: " + this.items[i].caj_cdgo + " // Inventario: " + this.items[i].lgj_nmro;
        }

        report.push(item);

        // Documentos relacionados
        if (this.items[i].show){
          for (var j = 0; j < this.items[i].expediente_docs.length; j++){
            let item2 = {};
            item2.show = "*";
            item2.tcr_nmbre = this.tpocorName(this.items[i].expediente_docs[j].tcr_id);
            item2.dcm_ano = this.items[i].expediente_docs[j].dcm_ano;
            item2.dcm_cdgo = this.items[i].expediente_docs[j].dcm_cdgo;
            item2.Fecha = moment(this.items[i].expediente_docs[j].Fecha).format('DD/MMM/YYYY hh:mm A');
            item2.dcm_entidad = this.items[i].expediente_docs[j].dcm_entidad;
            item2.dcm_ciudad = this.items[i].expediente_docs[j].dcm_ciudad;
            item2.dcm_asunto = this.items[i].expediente_docs[j].dcm_asunto;
            item2.dcm_tomo = this.items[i].expediente_docs[j].dcm_tomo;
            item2.dcm_fcharad = moment(this.items[i].expediente_docs[j].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
            item2.dcm_tres = this.items[i].expediente_docs[j].dcm_tres;
            item2.restante = this.items[i].expediente_docs[j].restante;
            item2.dep_nmbre = this.items[i].expediente_docs[j].dep_nmbre;
            item2.ser_nmbre = this.items[i].expediente_docs[j].ser_nmbre;
            item2.dcm_anexos = this.items[i].expediente_docs[j].dcm_anexos;
            item2.usu_nmbre = this.items[i].expediente_docs[j].usu_nmbre;
            if (this.items[i].expediente_docs[j].dcm_archivado){
              item2.archivo = "Sala: " + this.items[i].expediente_docs[j].sal_nmbre + " // " + this.items[i].expediente_docs[j].tpa_nmbre + " // Caja: " + this.items[i].expediente_docs[j].caj_cdgo + " // Inventario: " + this.items[i].expediente_docs[j].lgj_nmro;
            }

            report.push(item2);
          }
        }
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.$store.state.companydata.cmp_nmbre],
        [moment(new Date()).format('DD/MMM/YYYY hh:mm A')],
        [],
        ["Documentos"],
        [],
        ["Relacionados", "Clase documento", "Año", "Consecutivo", "Fecha seguimiento", "Entidad", "Ciudad", "Asunto", "Tomo", "Fecha radicación", "Tiempo respuesta	", "Tiempo restante", "Dependencia", "Serie", "Anexos", "Usuario", "Archivado"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A7" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    onResize: function (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
    },
    reduceText(text, number){
      if (text){
        return text.length > number ? text.substring(0,number) + '...' : text;
      } else {
        return '';
      }
    },
    consultarDetalles(array, index){
      this.detalles = {};
      this.detalles.doc = this.tpocorName(array[index].tcr_id) + '-' + array[index].dcm_ano + '-' + array[index].dcm_cdgo;
      this.detalles_items = [];
      var items = [];
      items.push({ from: array[index].dcm_fcharad, title: array[index].usu_nmbre, description: '(Radicado)', step:'' });
      let uri = '/docs/seguimientos';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, { id: array[index]._id })
      .then((response) => {        
        console.log(response.data);
        var seg = response.data.seguimientos;
        for (let i = 0; i < seg.length; i++){
          for (let j = 0; j < this.usuarios.length; j++){
            if (seg[i].ParaUsuarioId == this.usuarios[j]._id){
              seg[i].ParaUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
              j = this.usuarios.length;
            }
          }
          for (let s = 0; s < this.usuarios.length; s++){
            if (seg[i].DeUsuarioId == this.usuarios[s]._id){
              seg[i].DeUsuarioNombre = this.usuarios[s].usu_nmbre + ' ' + this.usuarios[s].usu_aplldo;
              s = this.usuarios.length;
            }
          }
          for (let k = 0; k < this.estados.length; k++){
            if (seg[i].EstadoId == this.estados[k]._id){
              seg[i].EstadoNombre = this.estados[k].Nombre;
              k = this.estados.length;
            }
          }
          var t2 = new Date(seg[i].Fecha);
          var t1 = new Date(items[items.length - 1].from);
          var dif = Math.abs(t2 - t1);
          //debugger;
          var step = Math.floor(dif/(1000*60*60*24));
          items.push({ from: seg[i].Fecha, deUsuario: seg[i].DeUsuarioNombre, title: seg[i].ParaUsuarioNombre, description: '(' + seg[i].EstadoNombre + ') ' + seg[i].Descripcion, 'step': '(' + step + 'D+)' });
        }
        this.detalles_items = items;
        $('#timelineModal').modal('show');
      })
      .catch((err) => {
        
      });
    },
    listarAdjuntos(a){
      this.files = a;
      this.allfiles = { download: false, downloadPercentage: 0 };
    },
    isPdf(adj_nmbre){
      if (adj_nmbre){
        var ext = adj_nmbre.substring(adj_nmbre.lastIndexOf('.'));
        if (ext.toLowerCase() == '.pdf'){
          return true;
        }
        return false;
      } else {
        return false;
      }
    },    
    async viewPdf(_id, adjuntos, x){
      try {
        this.modalMsg = 'Por favor espere mientras se muestra el archivo..';
        this.showProgress = true;

        this.pdfFile = {};
        let path = '';
        let name = '';
        if (adjuntos[x].adj_path){
          path = adjuntos[x].adj_path;
        } else {
          name = adjuntos[x].adj_nmbre
        }
        var data = {       
              _id,
              cmp_id: this.$store.state.company,
              path,
              name: encodeURIComponent(name)
        }

        let base_url = process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

        const response = await this.axios({
          url: `${base_url}/filesystemprovider/pass`,
          method: 'POST',
          data,
          headers: {
            'Authorization': sessionStorage.getItem('jwtToken')
          },
        });

        const token = response?.data?.tk;
        var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

        this.pdfFile = {
          url: url,
          adj_nmbre: name
        };

        $('#pdfModal').modal('show');
        this.showProgress = false;
      } catch(err) {
        this.message = 'Error al mostrar el archivo.';
        this.showProgress = false;
      } 
    },
    downloadFile(_id, adjuntos, x){
      const downloadProgress = (adjuntos, x) => (progressEvent) => {
        adjuntos[x].downloadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        this.$set(adjuntos, x, adjuntos[x]);
      }

      adjuntos[x].download = true;
      adjuntos[x].downloadPercentage = 0;
      this.$set(adjuntos, x, adjuntos[x]);

      this.axios({
        url: '/api/download',
        method: 'POST',
        data: { 'cmp_id': this.$store.state.company, '_id': _id, 'usu_cdgo': this.$store.state.user, 'adj_nmbre': adjuntos[x].adj_nmbre, 'temp': false, 'download': true, 'ts': this.timestamp },
        responseType: 'blob',
        onDownloadProgress: downloadProgress(adjuntos, x).bind(this)            
      })
      //.then(wait(5000))
      .then((response) => {
          adjuntos[x].download = false;
          this.$set(adjuntos, x, adjuntos[x]);
                    
          const url = window.URL.createObjectURL(new File([response.data], adjuntos[x].adj_nmbre, {type: 'application/octet-stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', adjuntos[x].adj_nmbre);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();          
      })
      .catch(err => {
          adjuntos[x].download = false;
          this.$set(adjuntos, x, adjuntos[x]);
      });
    },    
    downloadAllFiles(item, adjuntos, allfiles){
      const downloadProgress = (allfiles) => (progressEvent) => {
        allfiles.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        //this.$set(adjuntos, x, adjuntos[x]);
      }

      allfiles.download = true;
      allfiles.downloadPercentage = 0;
      //this.$set(adjuntos, x, adjuntos[x]);

      var _id = item._id;

      var tcr_cdgo = '';
      for (var i = 0; i < this.tpocors.length; i++){
        if (item.tcr_id == this.tpocors[i]._id){
          tcr_cdgo = this.tpocors[i].tcr_cdgo;
          i = this.tpocors.length;
        }
      }

      var zip = tcr_cdgo + '-' + item.dcm_ano + '-' + item.dcm_cdgo + '.zip';


      this.axios({
        url: '/api/download-all',
        method: 'POST',
        data: { 'cmp_id': this.$store.state.company, '_id': _id, 'usu_cdgo': this.$store.state.user, 'temp': false, 'ts': this.timestamp, 'files': adjuntos, 'zip': zip },
        responseType: 'blob',
        onDownloadProgress: downloadProgress(allfiles).bind(this)            
      })
      //.then(wait(5000))
      .then((response) => {
          allfiles.download = false;
          //this.$set(adjuntos, x, adjuntos[x]);
                    
          const url = window.URL.createObjectURL(new File([response.data], zip, {type: 'application/octet-stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', zip);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();          
      })
      .catch(err => {
          allfiles.download = false;
          //this.$set(adjuntos, x, adjuntos[x]);
      });
    },    
    percentageValue(p){
      return 'width:' + p + '%';
    },
    async fetchTimeStamp(){
      let uri = '/api/timestamp';
      await this.axios.post(uri)
      .then((response) => {
        this.timestamp = response.data.timestamp;
      })
      .catch((err) => {
        alert('No se pudo crear el identificador temporal. Consulte con el administrador.');
      });
    },
    viewHistory(item){
      var id = item._id;
      var tcr_cdgo = '';
      for (var i = 0; i < this.tpocors.length; i++){
        if (item.tcr_id == this.tpocors[i]._id){
          tcr_cdgo = this.tpocors[i].tcr_cdgo;
          i = this.tpocors.length;
        }
      }
      
      var rad = tcr_cdgo + '-' + item.dcm_ano + '-' + item.dcm_cdgo;
      this.$router.replace({ name: 'viewHistory', params: { id: id, rad: rad, search: this.search } });      
    },
    async showHistory(){
      let uri = '/tpousus/edit/' + String(this.$store.state.userdata.type);
      await this.axios.get(uri)
      .then(response => {
        if (response.data.tus_cdgo >= 0 && response.data.tus_cdgo <= 2){
          this.showHistoryButton = true;
        }
      });
    },
    setEntidad(i){
      var sala = this.findParent(this.treeData[0].children, this.active[0]);
      console.log(JSON.stringify(sala));
      console.log(JSON.stringify(i));
      
      this.search = {
        ent_id: i.id,
        sal_id: sala.id
      };
      
      this.showQueries('A');
      
    },

  } //FIN METODOS
}
</script>

<style>
.disabled {
  pointer-events:none;
  opacity:0.6;
}

.subitem {
  background-color: #FCF3CF;
}

.selected {
  background-color: #D6EAF8;
}

.checked {
  background-color: #b1f9d3;
}

.bgtable {
  background-color: #FFFFFF;
}

.table-condensed {
  font-size: 13px;
  line-height: 10px;
}

.archivadocss {
  font-size: 11px;
  line-height: 11px;
  text-align: left;
}

.bgarchive {
  background-color: #EEEEEE;
  /*border: 2px solid #CFD8DC;*/
  /*resize: horizontal;*/
  overflow:hidden;
}

.nuevo {
  font-weight: bold;
  /*background-color: #EBEDEF;*/
}

.path-text {
  font-weight:normal;
  text-decoration: underline;
  color:darkblue;
}

.asuntocss {
  height: 40px;
}

#area {
  position: relative;
  width: 100%;
  height: 100%;
  /*background: green;*/
}

#container {
  position: absolute;
  overflow: hidden;
  /* background: blueviolet;*/
  height: calc(100vh - 290px);
  width: 100%;
  /*border: 1px solid #c9c4ca;*/
 }

#left_panel {
  /* position: relative; */
  overflow: scroll;
  float: right;
  /*background: gray;*/
  height: 100%;
  width: 0px;
  /* left: 0px; */
  border: 2px solid #c9c4ca;
 }

#right_panel {
  position: relative;
  overflow: scroll;
  float: right;
  /*background: rgb(236, 188, 28);*/
  height: 100%;
  /*width: 100%;*/
  /* right: 0px; */
  border: 2px solid #c9c4ca;
 }

 #resizer {
    /* position: absolute; */
    /* overflow: hidden; */
    float: right;
    /*left: 20%;
    top: 0px;*/
    width: 0px;
    height: 100%;
    background: #737074;
    cursor: col-resize;
    z-index: 1000;
    border: 4px solid #737074;
 }

 #detalles {
   position:static;
 }

.listitems {
    /*position: absolute;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    /*color: #fff;*/
    background: white;
    /*height: auto;*/
}

.bgitems {
  /*position: absolute;*/
  /*right: 0;*/
  /*top: 0;*/
  /*bottom: 0;*/
  /*width: 30%;*/
  /*color: #fff;*/
  background-color: white;
  /*border: 2px solid #CFD8DC;*/
  /*height: auto;*/
}
</style>
